import React, { Component } from 'react';
import axios from 'axios';
import latest_time_icon from '../../images/latest_time_icon.png';
import title_icon from '../../images/title_icon.png';
import { Link } from 'react-router-dom';
import title_icon_bor from '../../images/title_icon_bor.png';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { Common } from '../../util/Common';
import ReactHtmlParser from 'react-html-parser';
import video_play from '../../images/video-play.png';

const FAVOURITE_API = process.env.REACT_APP_API_ENDPOINT + 'fevorite';
const API = process.env.REACT_APP_API_ENDPOINT + 'related_recipes';
let isFirst = 2;
class Related_recipe extends Component {
	constructor(props) {
		super(props);
		this.state = {
			hits: [],
			user: '',
		};
		this.favourite = this.favourite.bind(this);
		this.createNotification = this.createNotification.bind(this);
	}
	createNotification = (type, message) => {
		return () => {
			switch (type) {
				default:
					return 'default';
				case 'success':
					NotificationManager.success(message, 'Title here', 1000);
					this.componentDidMount();
					break;
				case 'error':
					NotificationManager.error(message, 'Error!', 5000);
					break;
			}
		};
	};
	favourite(is_favourite, recipeID) {
		var isFav
		(() => {
			if (is_favourite === 1) {
				return isFav = 0
			}
			else {
				return isFav = 1
			}
		})()
		let reqParam = {
			user_id: localStorage.getItem('id'),
			recipe_id: recipeID,
			is_favourite: isFav
		}
		axios.post(FAVOURITE_API, reqParam)
			.then(response => {
				this.createNotification('success', response.data.message)();
			})
	}
	// componentWillReceiveProps(nextProps) {
	// 	this.componentDidMount()
	// }
	componentDidMount() {
		
			
		let search = window.location.href;
		var spilt_url = search.split('/');
		var splt_str = spilt_url[4];
		let reqParam = {
			slug: splt_str,
			user_id: localStorage.getItem('id'),
		}
		axios.post(API, reqParam)
			.then(result =>{
				this.setState({
					hits: result.data.related_recipes
				});
				setTimeout(()=>{
					new Common().loadScript([
						'/assets/js/related_recipe.js'
					]);
				},1000);
			})
			.catch(error => {
				console.log(error);
			});
	}
	render() {
		var userID = localStorage.getItem('id')
		if (userID && isFirst === 2) {
			this.componentDidMount();
			isFirst = 1;
		}
		const { hits } = this.state
		return (
			<div className="container">
				<div className="row">
					<div className="col-lg-12 col-12">
						<div className="title_head">
							<h3 className="wow bounceIn animated"><span>Recetas</span> Relacionadas</h3>
							<img src={title_icon_bor} alt="img" />
						</div>
					</div>
				</div>
				<div className="owl-carousel owl-theme  fadeIn wow animated" data-wow-delay=".5s" id="latest_slider2">
					{hits.map(hit =>
						<div className="item ">
							<div className="recipes_box">
								<div className="recipes_img">
								<a href={"/recipe/" + hit.title_slug}>
									<span className="recipe_plan">
										<img src={hit.recipe_image} alt="img" />
									</span>
									{( hit.video === true) 
											?
													<span class="video_button">
														<img src={video_play} alt="" width="40"/>
													</span>
													:
													''
											}
									<div className="time_bottom">
										<span><img src={latest_time_icon} alt="img" /> {hit.total_time}</span>
									</div>
									</a>
									{(() => {
										if (userID != null) {
											return <button
												className="like"
												onClick={this.favourite.bind(this, hit.is_favourite, hit.id)}
											><i className={hit.is_favourite === 1 ? 'fas fa-heart' : 'fas fa-heart unliked'}>
												</i></button>
										}
										else {
											return <button
												className="like"
												data-toggle="modal"
												data-target="#myModal"
											>
												<i className="fas fa-heart unliked"></i></button>
										}
									})()}
								</div>
								<div className="recipes_detail">
									<h5>{hit.dish_style}</h5>
									<h4>{hit.title}</h4>
									<span className="title_small"><img src={title_icon} alt="img" /> </span>
									<p>{ReactHtmlParser(hit.recipe_details)}
									</p>
									<a href={"/recipe/" + hit.title_slug} className="btn_cus">Ver Más</a>
								</div>
							</div>
						</div>
					)}
				</div>
				<NotificationContainer />
			</div>
		)
	}
}

export default Related_recipe
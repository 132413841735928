import React, { Component } from 'react';
import axios from 'axios';
import title_icon_bor from '../../images/title_icon_bor.png';
import latest_time_icon from '../../images/latest_time_icon.png';
import video_play from '../../images/video-play.png';
import title_icon from '../../images/title_icon.png';
import UserLogin from './Login';
import { Link } from 'react-router-dom';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import Pagination from "react-js-pagination";
import ReactHtmlParser from 'react-html-parser';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const API = process.env.REACT_APP_API_ENDPOINT + 'popular_recipes';
const FAVOURITE_API = process.env.REACT_APP_API_ENDPOINT + 'fevorite';
let isFirst = 2;

class Most_popular extends Component {
	constructor(props) {
		super(props);
		this.state = {
			hits: [],
			current_page: 1,
			lastPage: null,
			user: '',
		};
		this.favourite = this.favourite.bind(this);
		this.createNotification = this.createNotification.bind(this);
	}
	createNotification = (type, message) => {
		return () => {
			switch (type) {
				default:
					return 'default';
				case 'success':
					NotificationManager.success(message, 'Title here', 1000);
					this.fetchEntities();
					break;
				case 'error':
					NotificationManager.error(message, 'Error!', 5000);
					break;
			}
		};
	};
	favourite(is_favourite, recipeID) {
		var isFav
		(() => {
			if (is_favourite === 1) {
				return isFav = 0
			}
			else {
				return isFav = 1
			}
		})()
		let reqParam = {
			user_id: localStorage.getItem('id'),
			recipe_id: recipeID,
			is_favourite: isFav
		}
		axios.post(FAVOURITE_API, reqParam)
			.then(response => {
				this.createNotification('success', response.data.message)();
			})
	}
	fetchEntities() {
		let reqParam = {
			page: this.state.current_page,
			user_id: localStorage.getItem('id')
		}
		axios.post(API, reqParam)
			.then(result => this.setState({
				hits: result.data.data
			}));
	}
	prevPage() {
		this.setState({ current_page: this.state.current_page - 1 }, () => { this.fetchEntities() });
	}
	nextPage() {
		this.setState({ current_page: parseInt(this.state.current_page) + 1 }, () => { this.fetchEntities() });
	}
	// handleInput(e) {
	// 	this.state.current_page = e.target.value;
	// 	this.setState({ current_page: parseInt(e.target.value) }, () => { this.fetchEntities() });
	// }
	handlePageChange(pageNumber) {
		window.scroll(0,2300)
		this.setState({current_page: pageNumber}, () => { this.fetchEntities() });
	  }
	componentDidMount() {
		this.fetchEntities();
	}
	render() {
		var userID = localStorage.getItem('id');
		if (userID && isFirst === 2) {
			this.fetchEntities();
			isFirst = 1;
		}
		var page = this.state.current_page
		const { hits } = this.state;
		hits.map(hit =>
			<div>
				{this.state.lastPage = hit.last_page}
			</div>
		)
		// var pagesArray = [];
		// for (var i = 1; i <= this.state.lastPage; i++) {
		// 	pagesArray.push(<li className={i === page ? 'page-item active' : 'page-item'} >
		// 		<button value={i}
		// 			className="page-link"
		// 			onClick={e => this.handleInput(e, "value")}>
		// 			{i}
		// 		</button>
		// 	</li>);
		// }
		return (
			<div className="container">
				<div className="row">
					<div className="col-lg-12 col-12">
						<div className="title_head">
							<h3 className="wow bounceIn animated"><span>Recetas más </span> Populares</h3>
							<img src={title_icon_bor} alt="" />
						</div>
					</div>
				</div>
				{hits.map((hit, index) =>
					<div className="row" key={`most_pop_${index}`}>
						{hit.recipes.map((options, index) =>
							<div className="col-lg-6 col-sm-6 col-12" key={`most_pop_option_${index}`}>
								<div className="recipes_box fadeIn wow animated">
									<div className="recipes_img">
										<Link to={"/recipe/" + options.title_slug}>
											<span className="recipe_plan">
												{/* <img src={options.recipe_image} alt="" /> */}
												<LazyLoadImage
													effect="blur"
													delayTime="100"
													src={options.recipe_image}
													
												/>
											</span>
											{( options.video === true) 
											?
													<span class="video_button">
														<img src={video_play} alt="" width="40"/>
													</span>
													:
													''
											}
											<div className="time_bottom">
												<span><img src={latest_time_icon} alt="" />{options.total_time}</span>
											</div>
										</Link>
										{(() => {
											if (userID != null) {
												return <button
													className="like"
													onClick={this.favourite.bind(this, options.is_favourite, options.id)}
												><i className={options.is_favourite === 1 ? 'fas fa-heart' : 'fas fa-heart unliked'}>
													</i></button>
											}
											else {
												return <button
													className="like"
													data-toggle="modal"
													data-target="#myModal"
												>
													<i className="fas fa-heart unliked"></i></button>
											}
										})()}
									</div>
									<div className="recipes_detail">
										<h5>{options.dish_style}</h5>
										<h4>{options.title}</h4>
										<span className="title_small"><img src={title_icon} alt="" /> </span>
										<p>{ReactHtmlParser(options.recipe_details)}</p>
										<Link to={"/recipe/" + options.title_slug} className="btn_cus">Ver Más</Link>
									</div>
								</div>
							</div>
						)}
					</div>
				)}
				<div className="row">
					<div className="col-lg-12 col-12">
							{/* <li className="page-item prev">
								<button className="page-link"
									disabled={1 === this.state.current_page}
									onClick={e => this.prevPage()}
								>
									Previous
										</button>
							</li>
							{pagesArray} */}
							 <Pagination
								itemClass="page-item"
								linkClass="page-link"
								prevPageText='Prev'
								nextPageText='Next'
								firstPageText='First'
      							lastPageText='Last'
								activePage={this.state.current_page}
								itemsCountPerPage={1}
								totalItemsCount={this.state.lastPage}
								onChange={this.handlePageChange.bind(this)}
							/>
							{/* <li className="page-item next">
								<button className="page-link"
									disabled={this.state.lastPage === this.state.current_page}
									onClick={e => this.nextPage()}
								>
									Next
										</button>
							</li> */}
					</div>
				</div>
				<UserLogin />
				<NotificationContainer />

			</div>
		)
	}
}

export default Most_popular
import React, { Component } from 'react';
import '../css/responsive.css';
import latest_time_icon from '../images/latest_time_icon.png';
import video_play from '../images/video-play.png';
import title_icon from '../images/title_icon.png';
import axios from 'axios';
import UserLogin from './index/Login';
import queryString from 'query-string';
import { Link } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import $ from 'jquery';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { Common } from '../util/Common';


const API = process.env.REACT_APP_API_ENDPOINT + 'search';
const FAVOURITE_API = process.env.REACT_APP_API_ENDPOINT + 'fevorite';
let is_First = 2;
class Search extends Component {
	constructor(props) {
		super(props);
		this.state = {
			hits: [],
			lastPage: null,
			current_page: 1,
			name: this.props.match.params.text,
			text: "",
			user: '',
			isLoading: true,
		};
		this.favourite = this.favourite.bind(this);
		this.createNotification = this.createNotification.bind(this)
	}
	createNotification = (type, message) => {
		return () => {
			switch (type) {
				default:
					return 'default';
				case 'success':
					NotificationManager.success(message, 'Title here', 1000);
					this.fetchEntities();
					break;
				case 'error':
					NotificationManager.error(message, 'Error!', 5000);
					break;
			}
		};
	};
	favourite(is_favourite, recipeID) {
		var isFav
		(() => {
			if (is_favourite === 1) {
				return isFav = 0
			}
			else {
				return isFav = 1
			}
		})()
		let reqParam = {
			user_id: localStorage.getItem('id'),
			recipe_id: recipeID,
			is_favourite: isFav
		}
		axios.post(FAVOURITE_API, reqParam)
			.then(response => {
				this.createNotification('success', response.data.message)();
			})
	}
	fetchEntities() {
		let urlstring = queryString.parse(this.props.location.search)
		var text_string = urlstring.text;
		var sub_id_string = urlstring.sub_id;
		var diff_id_string = urlstring.difficultyLevel;
		var meal_id_string = urlstring.meal;
		var occasions_id_string = urlstring.occasions;
		var diet_id_string = urlstring.diet;
		var cusine_id_string = urlstring.cusine;
		var tag = urlstring.tag;
		var dishStyle_id_string = urlstring.dishStyle;
		this.setState({ text: text_string })
		var tags = urlstring.tags;
		var final_tags = "";
		var tags_type = typeof tags;
		if (tags_type === "string") {
			final_tags = tags;
		} else {
			$(tags).each(function (index, value) {
				if (index === tags.length - 1) {
					final_tags += value;
				} else {
					final_tags += value + ',';
				}
			});
		}
		let reqParam = {
			page: this.state.current_page,
			search: text_string,
			sub_category_id: sub_id_string,
			difficulty: diff_id_string,
			meal: meal_id_string,
			occasions: occasions_id_string,
			diet: diet_id_string,
			cuisine: cusine_id_string,
			dish_style: dishStyle_id_string,
			user_id: localStorage.getItem('id'),
			tags: final_tags,
			tag: tag
		}
		let fetchUrl = `${API}/?page=${this.state.currentPage}`;
		axios.post(fetchUrl, reqParam)
			.then(result => this.setState({
				hits: result.data.data
			}));
	}
	prevPage() {
		this.setState({ current_page: this.state.current_page - 1 }, () => { this.fetchEntities() });
	}
	nextPage() {
		this.setState({ current_page: parseInt(this.state.current_page) + 1 }, () => { this.fetchEntities() });
	}
	handleInput(e) {
		this.state.current_page = e.target.value;
		this.setState({ current_page: parseInt(e.target.value) }, () => { this.fetchEntities() });
	}
	componentDidMount() {
		this.fetchEntities()
		setTimeout(() => {
			this.setState({ isLoading: false })
			// console.log("setTimeout call")
		}, 1300);
		setTimeout(() => {
			new Common().loadScript([
				'/assets/js/custom.js'
			]);
		});
	}
	render() {
		const { isLoading } = this.state
		var userID = localStorage.getItem('id')
		if (userID && is_First === 2) {
			this.fetchEntities();
			is_First = 1;
		}
		var page = this.state.current_page
		const { hits } = this.state;
		hits.map(hit =>
			<React.Fragment>
				{this.state.lastPage = hit.last_page}
			</React.Fragment>
		)
		var pagesArray = [];
		for (var i = 1; i <= this.state.lastPage; i++) {
			pagesArray.push(<li className={i === page ? 'page-item active' : 'page-item'} >
				<button value={i}
					className="page-link"
					onClick={e => this.handleInput(e, "value")}>
					{i}
				</button>
			</li>);
		}
		return (
			<div>
				{(isLoading) && <div className="loading"></div>}
				{hits.map(hit =>
					<div>
						<section class="page_title_section">
							<div class="container">
								<div class="row">
									<div class="col-12">
										<h4 class="wow fadeIn ">
										Se encontraron {hit.total} resultados para :
											<span>
												{this.state.text}
											</span>
										</h4>
									</div>
								</div>
							</div>
						</section>
						<section class="searched_items">
							<div class="container">
								<div class="row">
									{hit.recipes.map(options =>
										<div class="col-12 col-lg-10 m-auto">
											<div class="searchitem wow fadeIn">
												<div class="row align-items-center">
													<div class="col-lg-6 pr-lg-0 pr-md-0 col-md-6">
														<Link to={"/recipe/" + options.title_slug}>
															<div class="recipe_img_conat">
																<div className="recipe_img">
																	<img src={options.recipe_image} alt="" />
																</div>
																{( options.video === true) 
																	?
																			<span class="video_button">
																				<img src={video_play} alt="" width="40"/>
																			</span>
																			:
																			''
																	}
																<span class="timing">
																	<img src={latest_time_icon} alt="" />
																	{options.total_time}
																</span>
															</div>
														</Link>
														<span class="like_section">
															{(() => {
																if (userID != null) {
																	return <button
																		className="like"
																		onClick={this.favourite.bind(this, options.is_favourite, options.id)}
																	><i className={options.is_favourite === 1 ? 'fas fa-heart' : 'fas fa-heart unliked'}>
																		</i></button>
																}
																else {
																	return <button
																		className="like"
																		data-toggle="modal"
																		data-target="#myModal"
																	>
																		<i className="fas fa-heart unliked"></i></button>
																}
															})()}
														</span>
													</div>
													<div class="col-lg-6 pl-lg-0 pl-md-0 col-md-6">
														<div class="abt_item">
															<p><span class="red">{options.dish_style}</span></p>
															<h2 class="name_rec">{options.title}</h2>
															<span class="title_small"><img src={title_icon} alt="" /> </span>
															<p class="about_recipe_te">{ReactHtmlParser(options.recipe_details)}</p>
															<div class="view_m_btn"><Link to={"/recipe/" + options.title_slug} class="btn_cus">Ver Más</Link></div>
														</div>
													</div>
												</div>
											</div>
										</div>
									)}
								</div>
								<div class="row">
									<div class="col-lg-10 m-auto col-12">
										<ul class="pagination wow fadeIn">
											<li class="page-item prev">
												<button class="page-link"
													disabled={1 === this.state.current_page}
													onClick={e => this.prevPage()}
												>
													Previous
												</button>
											</li>
											{pagesArray}
											<li class="page-item next">
												<button class="page-link"
													disabled={this.state.lastPage === this.state.current_page}
													onClick={e => this.nextPage()}
												>
													Next
												</button>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</section>
					</div>
				)}
				<UserLogin />
				<NotificationContainer />
			</div>
		)
	}
}

export default Search

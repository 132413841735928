import React, { Component } from 'react';
import axios from 'axios';
import '../css/responsive.css';
import UserLogin from './index/Login';
import title_icon_bor from '../images/title_icon_bor.png';
import latest_time_icon from '../images/latest_time_icon.png';
import title_icon from '../images/title_icon.png';
import { NotificationManager } from 'react-notifications';
import { Link } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';

const API = process.env.REACT_APP_API_ENDPOINT + 'combo_details_slug';
const FAVOURITE_API = process.env.REACT_APP_API_ENDPOINT + 'fevorite';

class Combo_details extends Component {
	constructor(props) {
		super(props);
		this.state = {
			hits: [],
			user: '',
			lastPage: null,
			current_page: 1,
			isLoading: true,
		};
		this.favourite = this.favourite.bind(this);
		this.createNotification = this.createNotification.bind(this);
	}
	createNotification = (type, message) => {
		return () => {
			
			switch (type) {
				default:
      				return 'default';
				case 'success':
					NotificationManager.success(message, 'Title here',1000);
					this.fetchEntities();
					break;
				case 'error':
					NotificationManager.error(message, 'Error!', 5000);
					break;
			}
		};
	};
	favourite(is_favourite, recipeID) {
		var isFav
			(() => {
				if (is_favourite === 1) {
					return isFav = 0
				}
				else {

					return isFav = 1
				}
			})()
		let reqParam = {
			user_id: localStorage.getItem('id'),
			recipe_id: recipeID,
			is_favourite: isFav
		}

		axios.post(FAVOURITE_API, reqParam)
			.then(response => {
				this.createNotification('success', response.data.message)();
			})
	}
	prevPage() {
		this.setState({ current_page: this.state.current_page - 1 }, () => { this.fetchEntities() });
	}
	nextPage() {
		this.setState({ current_page: parseInt(this.state.current_page) + 1 }, () => { this.fetchEntities() });
	}
	handleInput(e) {
		this.state.current_page = e.target.value;
		this.setState({ current_page: parseInt(e.target.value) }, () => { this.fetchEntities() });
	}
	fetchEntities() {
		const { match: { params } } = this.props;
		let reqParam = {
			page: this.state.current_page,
			slug: params.title_slug,
			user_id: localStorage.getItem('id'),
		}
		axios.post(API, reqParam)
		.then(result => this.setState({
				hits: result.data.data,
			}));
		}
		componentDidMount() {
			this.fetchEntities();
			setTimeout(() => {
				this.setState({ isLoading: false })
				// console.log("setTimeout call")
			}, 1300);
		}
		render() {
			const { isLoading } = this.state
			var page = this.state.current_page
			var userID = localStorage.getItem('id');
			const { hits } = this.state;
			
			hits.map(hit =>
				<div>
					{this.state.lastPage = hit.last_page}
					
				</div>
			)
		var pagesArray = [];
		for (var i = 1; i <= this.state.lastPage; i++) {
			pagesArray.push(<li className={i === page ? 'page-item active' : 'page-item'} >
				<button value={i}
					className="page-link"
					onClick={e => this.handleInput(e, "value")}>
					{i}
				</button>
			</li>);
		}
		return (
			<div>
				{(isLoading) && <div className="loading"></div>}
				{hits.map(hit =>
					<div>
						<section class="single_recipe_section mb-0 cobmbo_single">
							<div class="container">
								<div class="row">
									<div class="col-12">
										<ul class="bread_cum wow fadeInUp">
											<li><Link to="/">Home</Link></li>
											<li>></li>
											<li>{hit.title}</li>
										</ul>
									</div>
								</div>
								<div class="single_recipe">
									<div class="row">
										<div class="col-12 col-lg-8 m-auto col-md-12">
											<div class="row">
												<div class="col-12 col-lg-8 col-md-9 m-auto">
													<ul class="combo_re" >
														<li class="itm_se wow fadeIn">
															<img src={hit.image} alt="" />
														</li>
													</ul>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</section>
						<section class="most_popular_recipes favorite_dec mt-0 header_m">
							<div class="container">
								<div class="row">
									<div class="col-lg-12 col-12">
										<div class="title_head">
											<h3 class="wow bounceIn"><span></span>{hits.title}</h3>
											<p class="wow fadeInUp"><span class="red">Autor : </span>{hit.author_name}</p>
											<img src={title_icon_bor} alt="" />
										</div>
									</div>
								</div>
								<div class="row ">
									<div class="col-12"><div class="number_of_combo">{hit.recipe_count}</div></div>
									{hit.recipes.map(options =>
										<div class="col-lg-6 col-sm-6 col-12 ">
											<div class="recipes_box wow fadeIn">
												<div class="recipes_img">
												<Link to={"/recipe/" + options.title_slug} >
													<span class="recipe_plan">
														<img src={options.recipe_image} alt="" />
													</span>
													<div class="time_bottom">
														<span><img src={latest_time_icon} alt="" />{options.total_time}</span>
													</div>
												</Link>
													{(() => {
														if (userID != null) {
															return <button
																className="like"
																onClick={this.favourite.bind(this, options.is_favourite, options.id)}
															><i className={options.is_favourite === 1 ? 'fas fa-heart' : 'fas fa-heart unliked'}>
																</i></button>
														}
														else {
															return <button 
																className="like"
																data-toggle="modal"
																data-target="#myModal"
															>
																<i className="fas fa-heart unliked"></i></button>
														}
													})()}
												</div>
												<div class="recipes_detail">
													<h5>{options.dish_style}</h5>
													<h4>{options.title}</h4>
													<span class="title_small"><img src={title_icon} alt="" /> </span>
													<p>{ReactHtmlParser(options.recipe_details)}</p>
													<Link to={"/recipe/" + options.title_slug} class="btn_cus">Ver Más</Link>
												</div>
											</div>
										</div>
									)}
								</div>
								<div class="row">
									<div class="col-lg-12 col-12">
										<ul class="pagination wow fadeIn">
											<li class="page-item prev">
												<button class="page-link"
													disabled={1 === this.state.current_page}
													onClick={e => this.prevPage()}
												>
													Previous
										</button>
											</li>
											{pagesArray}
											<li class="page-item next">
												<button class="page-link"
													disabled={this.state.lastPage === this.state.current_page}
													onClick={e => this.nextPage()}
												>
													Next
										</button>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</section>
						<UserLogin />
					</div>
				)}
			</div>
		)
	}
}

export default Combo_details
import React, { Component } from 'react'
// import ReactToPrint from 'react-to-print';
import logo from '../images/logo.png';
import border_b from '../images/border_b.png';
import time_red from '../images/time_red.png'
import clock_red from '../images/clock_red.png'
import red_meal from '../images/red_meal.png'
import add from '../images/add.png'
import header_img from '../images/header_img.png'
import axios from 'axios';

const API = process.env.REACT_APP_API_ENDPOINT + 'recipe_details_slug';


class ComponentToPrint extends Component {
	constructor(props) {
		super(props);
		this.state = {
			hits: [],

		};

	}
	componentDidMount() {
		let search = window.location.href;
		var spilt_url = search.split('/');  //Split Url
		var splt_str = spilt_url[4];
		// const { match: { params } } = this.props;
		let reqParam = {
			slug: splt_str,
			user_id: this.state.user,
			// console.log(API,reqParam)
		}
		axios.post(API, reqParam)
			.then(result => this.setState({
				hits: result.data.data
			}));
	}

	render() {
		const { hits } = this.state;
		console.log(hits)
		return (
			<div>
				{hits.map(hit =>
					<div>
						<body style={{ fontFamily: 'Lato', margin: '0' }}>
							<div style={{ display: 'block', margin: '20px auto', padding: '30px', color: '#333', fontSize: '14px' }} >
								<table style={{ width: '100%', borderCollapse: 'collapse', marginBottom: '40px' }}>
									<tr>
										<td style={{ textAlign: 'center', borderBottom: '1px solid #eee', paddingBottom: '20px' }}>
											<img src={logo} alt="img" />
										</td>
									</tr>
								</table>
								<table style={{ width: '100%', borderCollapse: 'collapse', backgroundImage: 'url(images/recipe_bg.png)', backgroundRepeat: 'noRepeat', backgroundSize: 'cover', backgroundPosition: 'center' }}>
									{/* <tr>
										<td><p style={{ paddingLeft: '10px', fontWeight: 'bold' }}><span style={{ color: '#e31d1a' }}>Home </span> {hit.title}</p></td>
									</tr> */}
									<tr>
										<td style={{ width: '38%', marginRight: '30px', paddingRight: '20px' }}>
											<p style={{ paddingLeft: '18px', paddingBottom: '20px' }}><img src={hit.recipe_image} style={{ width: '100%', height: '300px', objectFit: 'cover', borderRadius: '10px' }} alt="img" /></p>
										</td>
										<td style={{ width: '60%' }}>
											<p style={{ fontSize: '16px', fontWeight: 'bold', color: '#666', margin: '0' }}><span style={{ color: '#e31d1a', paddingRight: '10px' }}>Autor : </span>{hit.author_name}</p>

											<h2 style={{ fontSize: '34px', marginBottom: '16px', color: '#000', fontFamily: 'Lora', fontWeight: 'bold', marginTop: '8px' }}>{hit.title}</h2>

											<p style={{ fontSize: '16px', fontWeight: 'bold', color: '#666', marginBottom: '10px' }}><span style={{ color: '#e31d1a', paddingRight: '10px' }}>{hit.dish_style}</span></p>
											<p><img src={border_b} alt="img" /></p>
											<table style={{ width: '100%', borderCollapse: 'collapse' }}>
												<tr>
													<td style={{ padding: '8px 8px 8px 0', width: '30px' }}>
														<img src={time_red} style={{ paddingRight: '10px' }} alt="img" />
													</td>
													<td style={{ padding: '8px 8px 8px 0' }}>

														<p style={{ fontSize: '15px', color: '#666666', marginBottom: '0', marginTop: '0' }}>Tiempo de Preparación</p>
														<p style={{ marginTop: '5px', marginBottom: '0' }}><span style={{ fontSize: '18px', color: '#000', fontWeight: 'bold', width: ' 100%' }}>{hit.prep_time}</span></p>
													</td>
													<td style={{ padding: '8px 8px 8px 0', width: '30px' }}>
														<img src={red_meal} style={{ paddingRight: '10px' }} alt="img" />
													</td>
													<td style={{ padding: '8px 8px 8px 0' }}>
														<p style={{ fontSize: '15px', color: '#666666', marginBottom: '0', marginTop: '0' }}>Tiempo de Cocción</p>
														<p style={{ marginTop: '5px', marginBottom: '0' }}><span style={{ fontSize: '18px', color: '#000', fontWeight: 'bold', width: '100%' }}>{hit.cook_time}</span></p>
													</td>
													<td style={{ padding: '8px 8px 8px 0', width: '30px' }}>
														<img src={clock_red} style={{ paddingRight: '10px' }} alt="img" />
													</td>
													<td style={{ padding: '8px 8px 8px 0' }}>
														<p style={{ fontSize: '15px', color: '#666666', marginBottom: '0', marginTop: '0' }}>Tiempo Total</p>
														<p style={{ marginTop: '5px', marginBottom: '0' }}><span style={{ fontSize: '18px', color: '#000', fontWeight: 'bold', width: '100%' }}>{hit.total_time}</span></p>
													</td>

												</tr>

											</table>
										</td>


									</tr>
								</table>

								<table style={{ width: ' 100%', borderCollapse: 'collapse', marginBottom: '40px' }}>
									{/* <tr>
										<td style={{ padding: '8px 8px 8px 0', width: '30px' }}>
											<h2 style={{ fontFamily: 'Lora', fontWeight: '600', color: '#000', marginTop: '0', marginBottom: '0', fontSize: '34px' }}>About</h2>
										</td>
									</tr> */}
									<tr>
										<td>
											<p style={{ fontSize: '15px', color: '#666666', marginBottom: '0', marginTop: '0', lineHeight: '20px', wordBreak: 'breakAll' }}>{hit.recipe_details} </p>
										</td>
									</tr>
								</table>
								<table style={{ width: '100%', borderCollapse: 'collapse', marginBottom: '10px' }}>
									<tr style={{ padding: ' 8px 8px 8px 0', width: '30px' }}>
										<td><h2 style={{ fontFamily: 'Lora', fontWeight: '600', color: '#000', marginTop: ' 0', marginBottom: '0', fontSize: '34px' }}><span style={{ color: '#e31d1a' }}>Ingredientes</span> {hit.total_ingredients}</h2></td>
									</tr>
								</table>
								<table style={{ width: '100%', borderCollapse: 'collapse', marginBottom: '40px' }}>
									
										{hit.group_of_ingredients.map((option, index) =>
											<tr key={index}  >
												<td><h4 style={{color: '#231f20', fontWeight: '600'}}>{option.group_title}</h4></td>
												{option.group_ingredients.map((list,index)=>
											<tr key={index}  >
												
												<td style={{ width: ' 50%', paddingTop: '10px' }}>
													<table style={{ width: '95%', borderCollapse: 'collapse' }} >
														<tr style={{ borderBottom: '1px solid #ccc' }} >
															<td style={{ padding: '0 8px 8px 0', width: '30px', verticalAlign: 'top' }}>
																<img src={add} style={{ paddingRight: '10px' }} alt="img" />
															</td>
															<td style={{ padding: '8px 8px 8px 0', verticalAlign: 'top' }}>
																<p style={{ fontSize: '15px', color: '#333', marginBottom: '0', marginTop: '0' }}>{list.title}</p>
															</td>
														</tr>
													</table>
												</td>
											</tr>
											)}
											</tr>
										)}
											
								</table>
								<table style={{ width: '100%', borderCollapse: 'collapse', marginBottom: '40px' }}>
									<tr >										{(() => {
										if (hit.nutritions[0].title != null && hit.nutritions[0].unit != null && hit.nutritions[0].value != null) {
											return <td style={{ width: '100%', verticalAlign: 'top', background: ' #fef3f3', borderRadius: '10px', padding: ' 0 20px 19px' }}>
												<table style={{ width: '100%', borderCollapse: 'collapse' }}>
													<tr>
														<td>
															<h3 style={{ fontFamily: 'Lora', fontWeight: '600', color: '#000', marginTop: '0', marginBottom: '0', fontSize: '34px', textAlign: 'center', paddingTop: '14px' }}><span style={{ color: '#e31d1a' }}>Nutrients </span></h3>
															<p style={{ textAlign: 'center' }}><img src={header_img} alt="img" /></p>
														</td>
													</tr>
													<tr>
														<td style={{ width: '100%' }}>
															<table style={{ width: '100%', borderCollapse: 'collapse', marginBottom: '10px' }}>
																<tr>
																	<td style={{ width: '50%', verticalAlign: 'top' }}>

																		<ul style={{ width: '100%', textAlign: 'center', display: 'block' }}>
																			{hit.nutritions.map(nut =>
																				<li style={{ width: '120px', float: 'left', display: 'inlineBlock', background: '#fff', padding: '10px 7px', borderRadius: '10px', marginBottom: '15px', marginRight: '10px' }}><p style={{ marginTop: '0', marginBottom: '5px', fontFamily: 'Lora', wordBreak: 'breakAll' }}>{nut.title}	</p>
																					<span style={{ fontWeight: '600', color: '#000', fontSize: '18px' }}>{nut.value}</span></li>
																			)}
																		</ul>
																	</td>
																</tr>
															</table>
														</td>
													</tr>
												</table>
											</td>
										}
									})()}



									</tr>
								</table>
								<table style={{ width: '100%', borderCollapse: 'collapse' }}>
									<tr>
										<td>
											<h2 style={{ fontSize: '34px', marginBottom: '16px', color: '#000', fontFamily: 'Lora', fontWeight: 'bold', marginTop: '8px' }}>Preparación</h2>

										</td>
									</tr>
								</table>
								<table style={{ width: '100%', borderCollapse: 'collapse' }}>
									<tr>
										<td style={{ width: '70%', verticalAlign: 'top' }}>
											<table style={{ width: '95%', borderCollapse: 'collapse' }}>
												{hit.steps.map(stp =>
													<tr>
														<td style={{ width: '60px', verticalAlign: 'top', paddingBottom: '15px', paddingTop: '10px' }}>
															<span style={{ display: 'block', width: '40px', height: '40px', textAlign: 'center', lineHeight: '40px', background: '#e31d1a', color: '#fff', borderRadius: '50%' }}>{stp.step}</span>					</td>
														<td style={{ verticalAlign: 'top', paddingBottom: '15px', paddingTop: '15px' }}>
															<p style={{ marginTop: '0', marginBottom: '5px', wordBreak: 'breakAll' }}>{stp.title}</p>
															{stp.images.map(imgs => {
																if (imgs.type === "image") {
																	return <img src={imgs.url} style={{ marginRight: '6px', marginTop: '8px', objectFit: 'cover', width: '300px', borderRadius: '10px', overflow: 'hidden', height: '150px' }} alt="video is not supported" />
																}
															}

															)}
														</td>
													</tr>
												)}
											</table>
										</td>
									</tr>
								</table>
							</div>
						</body>
					</div>
				)}
			</div>
		)
	}
}

export default ComponentToPrint

import React, { Component } from 'react'
import axios from 'axios';
const API = process.env.REACT_APP_API_ENDPOINT + 'static_pages';

class Terms extends Component {
	constructor(props) {
		super(props);
		this.state = {
			hits: [],
		};
	}
	componentDidMount() {
		let reqParam = {
			page_name: "Tips and Terms & Conditions"
		}
		axios.post(API, reqParam)
			.then(result => this.setState({
				hits: result.data.data
			}));
	}
	render() {
		const { hits } = this.state;
		console.log({ hits })
		return (
			<div className="container">
				<div className="row">
					<div className="col-lg-12 col-12">
						<div className="title_head">
							{/* <h3 className="wow bounceIn animated">{hits.title}</h3> */}
							<h3 className="wow bounceIn animated">Términos y Condiciones</h3>
						</div>
					</div>
				</div>
				<div class="single_recipe">
					<div class="row">
						<div class="col-12 col-lg-8 m-auto col-md-12">
							<div dangerouslySetInnerHTML={{ __html: hits.body }} />
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default Terms

import React, { Component } from 'react';
import axios from 'axios';
import UserLogin from './Login';
import { NotificationManager } from 'react-notifications';
import { Link } from 'react-router-dom';
import title_icon_bor from '../../images/title_icon_bor.png';
import try_combo_icon from '../../images/try_combo_icon.png';
const API = process.env.REACT_APP_API_ENDPOINT + 'combos';
const FAVOURITE_API = process.env.REACT_APP_API_ENDPOINT + 'fevorite';
let isFirst = 2;

class Combos extends Component {
	constructor(props) {
		super(props);
		this.state = {
			hits: [],
			user: '',
			isLoading: true,
		};
		this.favourite = this.favourite.bind(this);
		this.createNotification = this.createNotification.bind(this);
	}
	createNotification = (type, message) => {
		return () => {
			switch (type) {
				case 'success':
					NotificationManager.success(message, 'Title here', 1000);
					this.fetchEntities();
					break;
				case 'error':
					NotificationManager.error(message, 'Error!', 5000);
					break;
				default:
					return 'foo';
			}
		};
	};
	favourite(is_favourite, recipeID) {
		var isFav
		(() => {
			if (is_favourite === 1) {
				return isFav = 0
			}
			else {
				return isFav = 1
			}
		})()
		let reqParam = {
			user_id: localStorage.getItem('id'),
			recipe_id: recipeID,
			is_favourite: isFav
		}
		axios.post(FAVOURITE_API, reqParam)
			.then(response => {
				this.createNotification('success', response.data.message)();
			})
	}
	fetchEntities() {
		let reqParam = {
			user_id: localStorage.getItem('id'),
		}
		axios.post(API, reqParam)
			.then(result => this.setState({
				hits: result.data.combos,
			}));
	}
	componentDidMount() {
		this.fetchEntities();
		setTimeout(() => {
			this.setState({ isLoading: false })
		}, 3800);
	}
	render() {
		const { isLoading } = this.state
		var userID = localStorage.getItem('id')
		if (userID && isFirst === 2) {
			this.fetchEntities();
			isFirst = 1;
		}

		const { hits } = this.state;
		return (
			<div className="container">
				{(isLoading) && <div className="loading"></div>}
				<div className="row">
					<div className="col-lg-12 col-12">
						<div className="title_head">
							<h3 className="wow bounceIn animated"><span>Prueba estos </span>  Combos</h3>
							<img src={title_icon_bor} className="wow fadeIn animated" alt="" />
						</div>
					</div>
				</div>

				<div className="owl-carousel owl-theme fadeIn wow animated" id="tri_combo">
					{hits.map((hit,index) =>
						<div className="item" key={`combo_${index}`}>
							<div className="row">
								<div className="col-lg-6 col-sm-6 col-12">
									<div className="try_left">
										<div className="try_img">
										<Link to={"/combo-details/" + hit.title_slug} >
											<span className="recipe_plan">
												<img src={hit.image} alt="" />
											</span>
											</Link>
										</div>
										<div className="recipes_detail">
											<h4 className="wow fadeInUp animated">{hit.title}</h4>
											<h5 className="wow fadeInUp animated">Autor: <b>{hit.author_name}</b> </h5>
											<img src={try_combo_icon} alt="" />
										</div>
									</div>
								</div>
								<div className="col-lg-6 col-sm-6 col-12">
									<div className="right_con">
										<span className="right_iteam">{hit.recipe_count} Recetas</span>
										{hit.recipes.map((option,index) =>
											<div className="try_combo_right" key={`most_pop_recipe_${index}`}>
												<div className="try_box_img"><img src={option.recipe_image} alt="" /> </div>
												<div className="try_box_detail">
													<h5>{option.dish_style}</h5>
													<h4>{option.title}</h4>
													{(() => {
														if (userID != null) {
															return <button
																className="like"
																onClick={this.favourite.bind(this, option.is_favourite, option.recipe_id)}
															><i className={option.is_favourite === 1 ? 'fas fa-heart' : 'fas fa-heart unliked'}>
																</i></button>
														}
														else {
															return <button
																className="like"
																data-toggle="modal"
																data-target="#myModal"
															>
																<i className="fas fa-heart unliked"></i></button>
														}
													})()}
												</div>
											</div>
										)}
									</div>
									<Link to={"/combo-details/" + hit.title_slug} className="btn_cus" >Ver Más</Link>
								</div>
							</div>
						</div>
					)}
				</div>
				<UserLogin />
			</div>
		)
	}
}

export default Combos
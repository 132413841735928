import React, { Component } from 'react';
import axios from 'axios';
import Related from './index/Related_recipe';
import print from '../images/print.png';
import share from '../images/share.png';
import title_icon from '../images/title_icon.png';
import time_red from '../images/time_red.png';
import { Link } from 'react-router-dom';
import red_meal from '../images/red_meal.png';
import clock_red from '../images/clock_red.png';
import add from '../images/add.png';
import header_img from '../images/header_img.png';
import dummy from '../images/dummy.png';
import UserLogin from './index/Login';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import ReactHtmlParser from 'react-html-parser';
import ReactToPrint from 'react-to-print';
import ComponentToPrint from './ComponentToPrint'
import '../css/bootstrap.min.css';
import '../css/responsive.css';
import '../css/style.css';
import $ from 'jquery'
// import InstagramEmbed from 'react-instagram-embed';
import { Common } from '../util/Common';
import {
	FacebookShareButton,
	EmailShareButton,
	FacebookIcon,
	EmailIcon,

} from 'react-share';

const API = process.env.REACT_APP_API_ENDPOINT + 'recipe_details_slug';
const FAVOURITE_API = process.env.REACT_APP_API_ENDPOINT + 'fevorite';
let isFirst = 2;

class Recipe_details extends Component {
	constructor(props) {
		super(props);
		this.state = {
			hits: [],
			playing: false,
			play: false,
			typeCheck: '',
			urlCheck: '',
			user: '',
			slug: '',
			isLoadingRecipe: true,
		};
		this.favourite = this.favourite.bind(this);
		this.checkType = this.checkType.bind(this);
		this.createNotification = this.createNotification.bind(this);
	}
	createNotification = (type, message) => {
		return () => {
			switch (type) {
				default:
					return 'default';
				case 'success':
					NotificationManager.success(message, 'Title here', 1000);
					this.fetchEntites();
					break;
				case 'error':
					NotificationManager.error(message, 'Error!', 5000);
					break;
			}
		};
	};
	favourite(is_favourite, recipeID) {
		var isFav
		(() => {
			if (is_favourite === 1) {
				return isFav = 0
			}
			else {
				return isFav = 1
			}
		})()
		let reqParam = {
			user_id: localStorage.getItem('id'),
			recipe_id: recipeID,
			is_favourite: isFav
		}
		axios.post(FAVOURITE_API, reqParam)
			.then(response => {
				this.createNotification('success', response.data.message)();
			})
	}
	checkType(type, url) {
		$('#vidoe_modal').css("opacity", "0");
		this.state.typeCheck = type
		this.state.urlCheck = url
		$('#vidoe_modal').css("opacity", "1");
		(() => {
			if (this.state.typeCheck === "video") {
				$('#imgID').hide()
				$('#videoID').attr("src", this.state.urlCheck)
				$('#videoID').show()
			}
			else {
				$('#videoID').hide()
				$('#imgID').attr("src", this.state.urlCheck)
				$('#imgID').show()
			}
		})()
	}
	// componentWillReceiveProps(nextProps) {
	// 	console.log('componentWillReceiveProps call')
	// 	this.fetchEntites(nextProps.match.params.title_slug);
	// 	// console.log(nextProps.match.params.title_slug);
	// }
	// componentWillMount(){

	// 	}
	fetchEntites() {
		const { match: { params } } = this.props;
		let reqParam = {
			slug: params.title_slug,
			user_id: localStorage.getItem('id'),
		}

		axios.post(API, reqParam)
			.then(result => this.setState({
				hits: result.data.data
			}))
			.catch(error => {
				console.log(error);
			});
	}
	componentDidMount() {
		this.fetchEntites();
		setTimeout(() => {
			new Common().loadScript([
				'/assets/js/custom.js'
			]);
		});
		setTimeout(() => {
			this.setState({ isLoadingRecipe: false })

		}, 2000);
	}

	render() {
		const { isLoadingRecipe } = this.state
		if (isLoadingRecipe) {
			return <div className="loading"></div>;
		}
		let search = window.location.href;
		var userID = localStorage.getItem('id');
		if (userID && isFirst === 2) {
			this.fetchEntites();
			isFirst = 1;
		}
		const { hits } = this.state;
		var shareUrl = search;
		const { title } = "Ladona Recipes";
		return (
			<React.Fragment>
				{hits.map(hit =>
					<div>
						<section className="single_recipe_section">
							<div className="container">
								<div className="row">
									<div className="col-12">
										<ul className="bread_cum wow fadeIn">
											<li><Link to="/">Home</Link></li>
											<li>></li>
											<li>{hit.title}</li>
										</ul>
									</div>
								</div>
								<div className="single_recipe">
									<div className="row">
										<div className="col-12 col-lg-7 col-md-12">
											{/* <div className="modal fade video" id="vidoe_modal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
												<div className="modal-dialog modal-dialog-centered modal-lg" role="document">
													<div className="modal-content">
														<div className="modal-body video_modal">
															<button className="action-close close cloase_btn" data-role="closeBtn" type="button" data-dismiss="modal">
																<span>×</span>
															</button>
															<div className="test_fb_wrapper">
																<div className="test_fb_wrapper_responsive">
																	<img src="" alt="" id="imgID" />
																	<iframe src="" title="myFrame" id="videoID" height="300px" width="400px" scrolling="no" frameBorder="0" allowtransparency="true" allowFullScreen={true}></iframe>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div> */}
											<ul className="recipe_slider_one  owl-carousel owl-theme wow bounceIn" data-wow-duration="1s" data-wow-iteration="1">
												{hit.recipe_images.map(options =>
													<div>
														<li className="itm_se" onClick={this.checkType.bind(this, options.type, options.url)}  >
															{(() => {
																if (options.type === "video") {
																	return <video width="100%" height="100%" controls src={options.url} type="video/mp4" />
																}
																else {
																	return <img src={options.url} alt="" />
																}
															})()}
														</li>
														{(() => {
															if (userID != null) {
																return <span className="like_section">
																	<button
																		className="like"
																		onClick={this.favourite.bind(this, hit.is_favourite, hit.id)}
																	><i className={hit.is_favourite === 1 ? 'fas fa-heart' : 'fas fa-heart unliked'}>
																		</i>
																	</button>
																</span>
															}
															else {
																return <span className="like_section">
																	<button
																		className="like"
																		data-toggle="modal"
																		data-target="#myModal"
																	><i className="fas fa-heart unliked"></i>
																	</button>
																</span>
															}
														})()}
													</div>
												)}
											</ul>
										</div>
										<div className="col-12 col-lg-5 col-md-12">
											<div className="right_btn wow fadeIn">
												<ReactToPrint
													trigger={() => <button className="btn print_btn mr-2" ><img src={print} alt="" /><span>Imprimir</span></button>}
													content={() => this.componentRef}
												/>
												<div style={{ display: 'none' }}>
													<ComponentToPrint ref={el => (this.componentRef = el)} />
												</div>
												<div className="share_btn">
													<img src={share} alt="" />
													<ul className="share_social">
														<li><FacebookShareButton
															url={shareUrl}
															quote={title}
															className="Demo__some-network__share-button">
															<FacebookIcon
																size={32}
																round />
														</FacebookShareButton></li>
														<li>
															<EmailShareButton
																url={shareUrl}
																title={title}
																separator=":: "
																className="Demo__some-network__share-button">
																<EmailIcon size={32} round />
															</EmailShareButton>
														</li>
													</ul>
												</div>
											</div>
											<div className="abt_item">
												<p><span className="red wow fadeIn">Autor :</span>{hit.author_name}</p>
												<h2 className="name_rec  wow bounce">{hit.title}</h2>
												<span className="style_dish wow fadeIn">{hit.dish_style}</span>
												<span className="title_small"><img src={title_icon} alt="" /> </span>
											</div>
											<div className="abt_time">
												<div className="row">
													<div className="col-12 col-md-4 col-lg-4">
														<div className="flex_time wow flipInY">
															<div className="div_1"><img src={time_red} alt="" /></div>
															<div className="text_t">
																<p className="">Tiempo de Preparación</p>
																<span className="time_text">{hit.prep_time}</span>
															</div>
														</div>
													</div>
													<div className="col-12 col-md-4 col-lg-4">
														<div className="flex_time wow flipInY">
															<div className="div_1"><img src={red_meal} alt="" /></div>
															<div className="text_t">
																<p className="">Tiempo de Cocción</p>
																<span className="time_text">{hit.cook_time}</span>
															</div>
														</div>
													</div>
													<div className="col-12 col-md-4 col-lg-4">
														<div className="flex_time wow flipInY">
															<div className="div_1"><img src={clock_red} alt="" /></div>
															<div className="text_t">
																<p className="">Tiempo Total</p>
																<span className="time_text">{hit.total_time}</span>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>

								</div>
							</div>
						</section>
						<section className="more_abt_recipe">
							<div className="container">
								<div className="row wow fadeIn">
									<div className="col-12">
										{/* <h2 className="more_abt_hd wow bounce">Acerca de</h2> */}
										<p >{ReactHtmlParser(hit.recipe_details)}</p>
									</div>
								</div>
							</div>
						</section>
						<section className="ingredients_section">
							<div className="container">
								<div className="row">
									<div className="col-12 ">
										<div className="title_head text-left pb-3">
											<h3 className="wow bounce">Ingredientes <span>{hit.total_ingredients}</span> </h3>
										</div>
									</div>
								</div>
								<div className="row wow fadeIn">
									<div className="col-12 col-md-7 col-lg-8">
									<div class="ingredients_list" >
								
								{hit.group_of_ingredients.map(option =>
                                    <div className="row">
                                       <div className="col-12 ">
                                          <p className="ingreGrouptitle">{option.group_title}</p>
                                       </div>
                                       {option.group_ingredients.map(list =>
                                          <div className="col-12 col-md-6 col-lg-6">
                                             <div className="ingredient_1">
                                                <a href={list.url} id="wordsBreak" target="_blank" className={(list.url) ? "used_in" : ""}><img src={add} alt="" />
                                                   <span >{list.quantity}</span>  <span>{list.unit_of_measure} </span>  <span>{list.title}</span>
                                                </a>
                                             </div>
                                          </div>
                                       )}
                                    </div>
                                 )}
                                 </div>
                                 
									</div>
									{(() => {
										// console.log(hit.nutritions)
										if (hit.nutritions[0].title != null && hit.nutritions[0].unit != null && hit.nutritions[0].value != null) {
											return <div className="col-12 col-md-5 col-lg-4">
												<div className="nutrients_div wow fadeIn">
													<div className="title_head pb-3">
														<h3><span>Nutrients </span></h3>
														<img src={header_img} alt="" />
													</div>
													<div className="row">
														{hit.nutritions.map(nut =>
															<div className="col-6">
																<div className="calories_div">
																	<p>{nut.title}	</p>
																	<span>{nut.value}</span>
																</div>
															</div>
														)}
													</div>
												</div>
											</div>
										}
									})()}

								</div>
							</div>
						</section>
						<section>
							<div className="container">

								<div className="row">
									<div className="col-12 col-lg-8 col-md-7">
										<h2 className="more_abt_hd wow fadeIn">Preparación</h2>
										<ul className="timeline ">
											{hit.steps.map(stp =>
												<li className="timeline-inverted wow fadeIn">
													<div className="timeline-badge"><i className="glyphicon glyphicon-credit-card">{stp.step}</i><span><img src={dummy} alt="" /></span></div>
													<div className="timeline-panel">
														<div className="timeline-heading">
															<p className="timeline-title">{stp.title}</p>
														</div>
														<div className="timeline-body">
															{stp.images.map(imgs =>
																<div className="image_for_pre" onClick={this.checkType.bind(this, imgs.type, imgs.url)} data-toggle="modal" data-target="#vidoe_modal">
																	{(() => {
																		if (imgs.type === "video") {
																			return <video src={imgs.url} type="video/mp4" />
																		}
																		else {
																			return <img src={imgs.url} alt="" />
																		}

																	})()}
																</div>
															)}
														</div>

													</div>
												</li>
											)}
										</ul>
									</div>
									<div className="col-12 col-lg-4 col-md-5">
										<h2 className="more_abt_hd wow fadeIn">Tags</h2>
										<ul className="tags_name">
											{hit.tags.map(tag =>
												<li><Link to={"/search?tag=" + tag}  >{tag}</Link></li>
											)}
										</ul>
									</div>
								</div>

							</div>
						</section>
						<section className="latest_recipes_sec pink_bg">
							<Related />
						</section>
					</div>

				)}
				<UserLogin />
				<NotificationContainer />

			</React.Fragment>


		)
	}
}
export default Recipe_details
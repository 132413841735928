import React, { Component } from 'react';
import logo from '../images/logo.png'
import url from '../pdf/flyer8.pdf'
import axios from 'axios';

class download extends Component {
	constructor(props) {
		super(props);
	
		this.fetchUrl = this.fetchUrl.bind(this);
	}
	

	async fetchUrl (url){
		const options = { url, method: 'GET', responseType: 'blob' };
		await axios(options).then((response) => {
		  const blob = window.URL.createObjectURL(new Blob([response.data]));
		  const link = document.createElement('a');
		  link.href = blob;
		  var name = url.split('/');
		  link.download = name[name.length - 1];
		  document.body.appendChild(link);
		  link.click();
		});
	}
	componentDidMount(){
		this.fetchUrl(url);
		
	}
	render() {
		return (
			<div>
{/* ]			<h1>download</h1>
			<img src={logo} alt={"logo"} className="fadeIn wow" /> */}
			</div>
		);
	}
}

export default download;
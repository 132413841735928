import React, { Component } from 'react'
import footer_img from '../images/footer_img.png'
import logo from '../images/logo.png'
import { Link } from 'react-router-dom';

var divStyle = {
  backgroundImage: 'url(' + footer_img + ')'
}
export default class Footer extends Component {


  render() {
 
    // loadScript('https://cdnjs.cloudflare.com/ajax/libs/wow/0.1.12/wow.min.js');
    // loadScript('/assets/js/owl.carousel.min.js');
    // loadScript('/assets/js/custom.js');
    // loadScript('/assets/js/jquery-3.4.1.min.js');
    // loadScript('/assets/js/bootstrap.min.js');
    

    // function loadScript(url) {
    //   var script = document.createElement('script');
    //   script.src = url;
    //   script.async = false;
    //   document.body.appendChild(script);
    // }
    return (
      <div>

        <footer>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="foot_top" style={divStyle}>
                  <img src={logo} className="wow fadeInUp" alt=""/>

                  <p className="wow fadeInUp"> Diviértete, inspírate y crea deliciosas recetas al mejor estilo de La Doña. Todos los ingredientes que necesitas para hacer un plato inolvidable los encuentras en Productos la Doña.</p>
                  <h4 className="wow fadeInUp">Socializa con Nosotros</h4>
                  <ul className="social_foot wow fadeInUp">
                    <li><a href="https://www.facebook.com/ladona.com.pa/"><i className="fab fa-facebook-f"></i></a></li>
                    <li><a href="https://instagram.com/productosladona_?igshid=YmMyMTA2M2Y="><i className="fab fa-instagram"></i></a></li>
                    <li><a href="https://www.youtube.com/channel/UCPLn5uOUgVzDU4gFfrcvlLQ"><i className="fab fa-youtube"></i> </a></li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="foot_bottom fadeInUp">
              <div className="row">
                <div className="col-lg-7 col-md-8 col-12">
                  <ul className="menu_foot">
                    <li><Link to="/"> Home  </Link></li>
                    <li><Link to="/about-us">Sobre Nosotros</Link></li>
                    <li><Link to="/contact-us/">Contáctanos</Link></li>
                    <li><Link to="/terms-conditions"> Términos y Condiciones </Link></li>
                    <li><Link to="privacy-policy">Políticas de Privacidad </Link></li>
                    <li><Link to="/product-policy">Políticas de servicio y productos </Link></li>
                  </ul>
                </div>

                <div className="col-lg-5 col-md-4 col-12">
                  <p> © Copyright 2020 Todos los derechos reservados. Desarrollado por <a href="https://www.keylimetec.com">www.keylimetec.com</a></p>
				  <button id="return-to-top"><i className="fa fa-angle-up"></i></button>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    )
  }
}

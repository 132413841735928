import React, { Component } from 'react';
import logo from '../images/logo.png'
import url from '../pdf/Catalogo familia productos-2.pdf'
import axios from 'axios';

class Catalog extends Component {
	constructor(props) {
		super(props);
	
		this.fetchUrl = this.fetchUrl.bind(this);
	}
	

	async fetchUrl (url){
		window.open(url,"_self");
	}
	componentDidMount(){
		this.fetchUrl(url);
		
	}
	render() {
		return (
			<div>

			</div>
		);
	}
}

export default Catalog;
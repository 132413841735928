import React, { Component } from 'react';
import axios from 'axios';
import '../css/responsive.css';
import latest_time_icon from '../images/latest_time_icon.png';
import { NotificationManager } from 'react-notifications';
import UserLogin from './index/Login';
import title_icon from '../images/title_icon.png';
import { Link } from 'react-router-dom';


const API = process.env.REACT_APP_API_ENDPOINT + 'fevorite_recipes';
const FAVOURITE_API = process.env.REACT_APP_API_ENDPOINT + 'fevorite';


class My_favourite extends Component {
	constructor(props) {
		super(props);
		this.state = {
			hits: [],
			isLoading: true,
			
		};
		this.favourite = this.favourite.bind(this);
		this.createNotification = this.createNotification.bind(this);
	}
	createNotification = (type, message) => {
		return () => {
			switch (type) {
				default:
      				return 'default';
				case 'success':
					NotificationManager.success(message, 'Title here',1000);
					 this.fetchApi()
					break;
				case 'error':
					NotificationManager.error(message, 'Error!', 5000);
					break;
			}
		};
	};
	favourite(is_favourite, recipeID) {
		var isFav
			(() => {
				if (is_favourite === 1) {
					return isFav = 0
				}
				else {

					return isFav = 1
				}
			})()
		var userID = localStorage.getItem('id')
		let reqParam = {
			user_id:userID,
			recipe_id: recipeID,
			is_favourite: isFav
		}
		axios.post(FAVOURITE_API, reqParam)
			.then(response => {
				this.createNotification('success', response.data.message)();
			})
	}
	componentDidMount() {
		this.fetchApi();
		setTimeout(() => {
			this.setState({ isLoading: false })
		}, 1300);
	}
	fetchApi(){
		var userID = localStorage.getItem('id')
		if(userID !== null)
		{
			let reqParam = {
				user_id: userID
			}
			axios.post(API, reqParam)
				.then(result => this.setState({
					hits: result.data.data
	
				}));	
		}
		else {
			window.location.href = "/";
		}
	}

	render() {
		const { isLoading } = this.state
		const { hits } = this.state;
		return (
			<div>
				{(isLoading) && <div className="loading"></div>}
				<section class="page_title_section">
					<div class="container">
						<div class="row">
							<div class="col-12">
								<h4 class="wow bounce">My Favorite <span></span></h4>
							</div>
						</div>

					</div>
				</section>
				<section class="most_popular_recipes favorite_dec ">
					<div class="container">
						<div class="">
						{(() => {
							if(typeof hits !== 'undefined') {
								return <div className="row">
									{hits.map(hit =>
										<div class="col-lg-6 col-sm-6 col-12" key={hit.id}>
											<div class="recipes_box wow fadeIn">
												<div class="recipes_img">
													<span class="recipe_plan">
														<img src={hit.recipe_image} alt="" />
													</span>
													<div class="time_bottom">
														<span><img src={latest_time_icon} alt="" />{hit.total_time}</span>
													</div>
													<button class="like"
														onClick={this.favourite.bind(this, hit.is_favourite, hit.id)}
													
													><i class="fas fa-heart">
														</i>
													</button>
												</div>
												<div class="recipes_detail">
													<h5>{hit.dish_style}</h5>
													<h4>{hit.title}</h4>
													<span class="title_small"><img src={title_icon} alt="" /> </span>
													<p>{hit.recipe_details}</p>

													<Link to={"/recipe/" + hit.title_slug} class="btn_cus">Ver Más</Link>
												</div>
											</div>
										</div>
									)}
								</div>
							} else{	
								return<h4 class="wow bounce">No favourite Recetas  available <span></span></h4>
							}
						})()}
						</div>
					</div>
				</section>
				<UserLogin/>				
			</div>
		)
	}
}

export default My_favourite
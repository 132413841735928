import React, { Component } from 'react'
import axios from 'axios';
import latest_time_icon from '../../images/latest_time_icon.png';
import title_icon from '../../images/title_icon.png';
import title_icon_bor from '../../images/title_icon_bor.png';
import UserLogin from './Login';
import { Link } from 'react-router-dom';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import ReactHtmlParser from 'react-html-parser';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import video_play from '../../images/video-play.png';

const API = process.env.REACT_APP_API_ENDPOINT + 'latest_recipes';
const FAVOURITE_API = process.env.REACT_APP_API_ENDPOINT + 'fevorite';
let isFirst = 2;

class Latest_recipe extends Component {
	constructor(props) {
		super(props);
		this.state = {
			hits: [],
			hits1: [],
		};
		this.favourite = this.favourite.bind(this);
		this.createNotification = this.createNotification.bind(this);
	}
	createNotification = (type, message) => {
		return () => {
			switch (type) {
				default:
					return 'default';
				case 'success':
					NotificationManager.success(message, 'Title here', 1000);
					this.fetchEntities();
					break;
				case 'error':
					NotificationManager.error(message, 'Error!', 5000);
					break;
			}
		};
	};
	favourite(is_favourite, recipeID) {
		var isFav
		(() => {
			if (is_favourite === 1) {
				return isFav = 0
			}
			else {

				return isFav = 1
			}
		})()
		let reqParam = {
			user_id: localStorage.getItem('id'),
			recipe_id: recipeID,
			is_favourite: isFav
		}
		axios.post(FAVOURITE_API, reqParam)
			.then(response => {
				this.createNotification('success', response.data.message)();
			})
	}
	fetchEntities() {
		let reqParam = {
			user_id: localStorage.getItem('id')
		}
		axios.post(API, reqParam)
			.then(result => this.setState({
				hits: result.data.latest_recipes
			}));
	}
	componentDidMount() {
		this.fetchEntities();
	}
	render() {
		var userID = localStorage.getItem('id')
		if (userID && isFirst === 2) {
			this.fetchEntities();
			isFirst = 1;
		}
		const { hits } = this.state;
		let new_array = [];
		
		if(hits.length){
		hits.forEach((element,index) => {
			if(index <=9){
				new_array.push(element)
			}
		});
	}
		

		return (
			<div className="container">
				<div className="row">
					<div className="col-lg-12 col-12">
						<div className="title_head">
							<h3 className="wow bounceIn animated"><span>Últimas</span> Recetas</h3>
							<img src={title_icon_bor} alt="" />
						</div>
					</div>
				</div>

				<div className="owl-carousel owl-theme fadeIn wow  slider_items" id="latest_slider">
					{new_array.map(hit =>
						<div>
							<div className="item " id="divAppend" key={hit.id}>
								<div className="recipes_box">
									<div className="recipes_img">
										<Link to={"/recipe/" + hit.title_slug}>
											<span className="recipe_plan">
												{/* <img src={hit.recipe_image} alt="" /> */}
												<LazyLoadImage
													effect="blur"
													delayTime="100"
													src={hit.recipe_image}
													
												/>
											</span>
											{( hit.video === true) 
											?
													<span class="video_button">
														<img src={video_play} alt="" width="40"/>
													</span>
													:
													''
											}
											<div className="time_bottom">
												<span><img src={latest_time_icon} alt="" />{hit.total_time}</span>
											</div>
										</Link>
										{(() => {
											if (userID != null) {
												return <button
													className="like"
													onClick={this.favourite.bind(this, hit.is_favourite, hit.id)}
												><i className={hit.is_favourite === 1 ? 'fas fa-heart' : 'fas fa-heart unliked'}>
													</i></button>
											}
											else {
												return <button
													className="like"
													data-toggle="modal"
													data-target="#myModal"
												>
													<i className="fas fa-heart unliked"></i></button>
											}

										})()}
									</div>
									<div className="recipes_detail">
										<h5>{hit.dish_style}</h5>
										<h4>{hit.title}</h4>
										<span className="title_small"><img src={title_icon} alt="" /></span>
										<p>{ReactHtmlParser(hit.recipe_details)}</p>
										<Link to={"/recipe/" + hit.title_slug} className="btn_cus">Ver Más</Link>
									</div>
								</div>
							</div>


						</div>
					)}
				</div>
				<UserLogin />
				<NotificationContainer />
			</div>
		)
	}
}
export default Latest_recipe
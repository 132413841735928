import React, { Component } from 'react'
import axios from 'axios';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import $ from 'jquery';

const API =  process.env.REACT_APP_API_ENDPOINT + 'contact_us';
class Contact_us extends Component {
	constructor(props) {
		super(props);
		this.state = {
			name: '',
			email:'',
			comment:'',
		};
		this.onChange      = this.onChange.bind(this);
		this.emailChange   = this.emailChange.bind(this);
		this.commentChange = this.commentChange.bind(this);
		this.onSubmit      = this.onSubmit.bind(this);
		this.createNotification = this.createNotification.bind(this);

	}
	onChange(e) {
		this.setState({ name: e.target.value });
	}
	emailChange(e) {
		this.setState({ email: e.target.value });
		//  console.log(this.state.email)
	}
	commentChange(e) {
		this.setState({ comment: e.target.value });
		//  console.log(this.state.email)
	}
	onSubmit(e) {
		this.fetchEntities();
	}
	createNotification = (type, message) => {
		return () => {
		  switch (type) {
			default:
      				return 'default';
			case 'success':
			  NotificationManager.success('Mensaje enviado.', 'Title here');
			  break;
			case 'error':
			  NotificationManager.error(message, 'Error!', 5000);
			  
			  break;
		  }
		};
	  };
	fetchEntities()
	{	
		$(".btn_cus").attr('disabled','disabled');
		let reqParam = {
			name : this.state.name,
			email : this.state.email,
			comment : this.state.comment,
		}
		// console.log(API,reqParam)
		axios.post(API,reqParam)
        .then(response => {
			console.log(response.data.message);
			this.createNotification('success', response.data.message)();
			setTimeout(function() {
				window.location.reload();
			}, 1000);
			  
        })
        .catch(error => {
		  this.createNotification('error', error.response.data.message)();
		  
		  setTimeout(function() {
			window.location.reload();
		}, 1000);
		  
      });
	}
	render() {
		return (
			<div id="content">
				<section class="contact_us">
					<div class="container">
						<div class="contact_inner">
							<div class="row">
								<div class="col-12">
									<div class="title_head">
										<h3>Contáctanos</h3>
									</div>
								</div>
							</div>

							<div class="row">
								<div class="col-lg-6">
									<div class="form-group"><lable>Nombre</lable>
										<input type="text"
											class="form-control"
											placeholder="User Name"
											name="name"
											onChange={this.onChange}
										/>
									</div>
								</div>
								<div class="col-lg-6">
									<div class="form-group">
										<lable>Email</lable>
										<input type="text"
											class="form-control"
											placeholder="Email"
										    onChange={this.emailChange}
										/>
									</div>
								</div>
								<div class="col-lg-12">
									
									<div class="form-group">
										<lable>Comentario</lable>
										<textarea rows="4" cols="50" class="form-control" onChange={this.commentChange}></textarea>
									</div>
								</div>
							</div>

							<div class="row mt-3">
								<div class="col-lg-12">
									<div class="form-group text-right">
										<button class="btn_cus" type="submit" onClick={this.onSubmit}>Enviar</button>
									</div>
								</div>
							</div>
							<NotificationContainer/>

						</div>


					</div>
				</section>
			</div>
		)
	}
}

export default Contact_us
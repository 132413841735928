
export class Common {
	loadScript(scriptSrc) {
		scriptSrc.forEach((source) => {
			var script = document.createElement('script');
			script.src = source;
			script.async = false;
			document.body.appendChild(script);
		});
	}
}
import React, { Component } from 'react'
import axios from 'axios';
const API = process.env.REACT_APP_API_ENDPOINT + 'static_pages';


class Privacy extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hits: [],
        };
    }
    componentDidMount() {
        let reqParam = {
            page_name: "How it Works"
        }
        axios.post(API, reqParam)
            .then(result => this.setState({
                hits: result.data.data
            }));
    }
    render() {
		// loadScript('/assets/js/jquery-3.4.1.min.js');
		// loadScript('/assets/js/bootstrap.min.js');
		// loadScript('/assets/js/owl.carousel.min.js');
		// loadScript('/assets/js/wow.js');
		// loadScript('/assets/js/custom.js');

		loadScript('https://code.jquery.com/jquery-3.5.1.min.js');
		loadScript('https://stackpath.bootstrapcdn.com/bootstrap/4.5.0/js/bootstrap.min.js');
		loadScript('/assets/js/owl.carousel.min.js');
		loadScript('https://cdnjs.cloudflare.com/ajax/libs/wow/1.1.2/wow.js');
		loadScript('/assets/js/custom.js');
	
		function loadScript(url) {
		var script = document.createElement('script');
		script.src = url;
		script.async = false;
		document.body.appendChild(script);
		}
        const { hits } = this.state;
        return (
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 col-12">
                        <div className="title_head">
                            <h3 className="wow bounceIn animated">Políticas de Privacidad</h3>
                        </div>
                    </div>
                </div>

                <div class="single_recipe">
                    <div class="row">
                        <div class="col-12 col-lg-8 m-auto col-md-12">
                            <div dangerouslySetInnerHTML={{ __html: hits.body }} />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Privacy

import React, { Component } from 'react';
import '../css/all.min.css';
import '../css/bootstrap.min.css';
import '../css/owl.carousel.min.css';
import '../css/owl.theme.default.min.css';
import '../css/animate.min.css';
import '../css/style.css';
import axios from 'axios';
import url from '../pdf/PRODUCTOS LA DONA - TES -LA MAGIA DE LAS ESPECIAS.pdf'
import url1 from '../pdf/Catalogo familia productos-3.pdf'
import url2 from '../pdf/RECETAS TIPICAS PANAMEÑAS 1.pdf'
import url3 from '../pdf/RECETARIO NAVIDEÑO -LA DOÑA 2020 1.pdf'
import logo from '../images/logo.png';
import { Link } from 'react-router-dom';
import spoon from '../images/spoon.png';
import arrow_double from '../images/arrow_double.png';
import search_icon from '../images/search_icon.png';
import UserLogin from './index/Login';
import { NotificationContainer, NotificationManager } from 'react-notifications';
// import { Redirect } from 'react-router';
import { Common } from '../util/Common';

var divStyle = {
	position: 'static'
}
const API = process.env.REACT_APP_API_ENDPOINT + 'filters';

class Header extends Component {
	constructor(props) {
		super(props);
		this.state = {
			hits: [],
			name: '',
			cate_id: [],
			floors: [],
			search_text: '',
			sub_id: '',
			isLogin: false,
			redirectToReferrer: false,
		};
		this.onChange = this.onChange.bind(this);
		this.fetchUrl = this.fetchUrl.bind(this);
		this.loginChange = this.loginChange.bind(this);
		this.Logout = this.Logout.bind(this);
		this.createNotification = this.createNotification.bind(this);
	}
	async fetchUrl (url){
		window.open(url,"_blank");
		// const options = { url, method: 'GET', responseType: 'blob' };
		// await axios(options).then((response) => {
		//   const blob = window.URL.createObjectURL(new Blob([response.data]));
		//   const link = document.createElement('a');
		//   link.href = blob;
		//   var name = url.split('/');
		//   link.download = name[name.length - 1];
		//   document.body.appendChild(link);
		//   link.click();
		// });
	}
	loginChange(stateLabel, value) {
		this.props.stateChange('isLogin', true);
		this.setState({ [stateLabel]: value });
		localStorage.setItem('isLogin', true);
	}
	createNotification = (type, message) => {
		return () => {
			switch (type) {
				default:
					return 'default'
				case 'success':
					// this.setState({ isLoading: false });
					NotificationManager.success('Logout Successfully', 'Title here',1200);
					break;
					
					}
				};
			};
	onChange(e) {
		this.setState({ name: e.target.value });
	}
	Logout() {
		this.loginChange('isLogin', false);
		localStorage.removeItem("isLogin");
		localStorage.removeItem("id");
		localStorage.removeItem("firstname");
		this.createNotification('success')();
		// localStorage.removeItem("id");
		this.setState({
			redirectToReferrer: true,
		});
	}
	componentDidMount() {
		axios.post(API)
			.then(result => this.setState({
				hits: result.data.data
			}));
		const common = new Common();
		setTimeout(()=>{
			common.loadScript([
				'/assets/js/menu.js'
			]);
		});
	}
	render() {
		// console.log("Header.js")
		let searchUrl = window.location.href;
		var spilt_url1 = searchUrl.split('/');  //Split Url
		var splt_strr = spilt_url1[3];
		if (splt_strr === 'my-favourite') {
			if (this.state.redirectToReferrer) {
				window.location.href = '/';
				// (<Redirect to={'/'} />)
				return ;
			}
		}
		var userID = localStorage.getItem('isLogin')
		var firstname = localStorage.getItem('firstname')
		const { hits } = (this.state);
		let search = window.location.href; //Get Url
		if (this.state.name !== undefined) {
			var name_val = "";
			var string = "text=";
		} else {
			name_val = this.state.name;
		}
		var sub_id1 = '&sub_id=';
		var spilt_url = search.split('/');  //Split Url
		var splt_str = spilt_url[3]; //Get search text from split url
		var splt_str1 = splt_str.split("="); // Split for get search text
		var splt_str2 = splt_str.split("&"); // Split for get search text
		if (typeof splt_str2[1] !== 'undefined') {
			var splt_str3 = splt_str2[1].split("&"); // Split for get search text
			var splt_str4 = splt_str3[0].split("="); // Split for get search text
			if (splt_str4[0] === "sub_id") {
				sub_id1 = '&sub_id=';
			} else {
				sub_id1 = '&sub_id=';
			}
		}
		var final_text = splt_str1[1]; //final search text
		if (final_text !== undefined) {
			var f_text = final_text;
		} else {
			f_text = "";
		}
		if (f_text !== "") {
			string = "text=" + f_text;

		} else {
			string = "text=" + this.state.name;
		}

		return (
			<header id="header-sroll">
				<div className="container">
					<div className="head_top">
						<div className="row align-items-end">
							<div className="col-lg-4 col-md-4 col-4">
								<ul className="top_social">
									
									<li><a target="_blank" href="https://www.facebook.com/ladona.com.pa/"><i className="fab fa-facebook-f swing wow"></i></a></li>
									<li><a target="_blank" href="https://www.instagram.com/productosladona_/?hl=es-la"><i className="fab fa-instagram swing wow"></i></a></li>
									<li><a target="_blank" href="https://www.youtube.com/channel/UCPLn5uOUgVzDU4gFfrcvlLQ"><i className="fab fa-youtube swing wow"></i> </a></li>
								</ul>
							</div>
							<div className="col-lg-4 col-md-4 col-4">
								<Link to="/" className="logo"><img src={logo} alt={"logo"} className="fadeIn wow" /></Link>
							</div>
							<div className="col-lg-4  col-md-4 col-4">
								{/* <span className="right_shop"><a href="https://trootechproducts.com:8014/">SHOP<img src={arrow_double} alt="arrow" /></a></span> */}
								{(() => {
									if (localStorage.getItem('isLogin') != null) {
										return <span className="right_shop dropdown ">
											<button className=" dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
											Hola, {firstname}
											</button>
											<div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
												<button className="dropdown-item" onClick={this.Logout}>Cerrar sesión</button>
											</div>
										</span>
									}
									else {
										return <span className="right_shop"><button data-toggle="modal"
											data-target="#myModal" >Iniciar Sesión<img src={arrow_double} alt="arrow" /></button></span>
									}
								})()}
							</div>
						</div>
					</div>
				</div>
				<div className="filter_menu_con">
					<div className="container">
						<div className="row">
							{hits.map((hit,index) => 
								<div key={index} className="col-lg-12">
									<nav className="navbar navbar-expand-lg navbar">
										<a className="navbar-brand" href="####"><img src={logo} alt="img" /></a>
										<div className="search_custom_div">
											<div className="custom_search">
												<form action="/search" className="search-container">
													<input type="text"
														id="search-bar"
														placeholder="Encuentra lo que quieres.."
														name="text"
														onChange={this.onChange}
													/>
													<a href={"/search?text=" + this.state.name} >
														<img className="search-icon" src={search_icon} alt="img" />
													</a>
												</form>
											</div>
										</div>
										<div className="menu_div" id="navbarNavDropdown">
											<ul className="navbar-nav">
												<li className="nav-item dropdown has-mega-menu" style={divStyle}>
													<button className="nav-link dropdown-toggle menu_btn" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Menú</button>
													<div className="dropdown-menu menu_container">
														<div className="px-0 container">
															<div className="row hide_xs adding_div">
																<div className="col-lg-3 col-md-3"><span className="menu_img"><img
																	src={spoon} alt="img" /></span></div>
																<div className="col-lg-3 col-md-3"><span className="menu_img"><img
																	src={spoon} alt="img" /></span></div>
																<div className="col-lg-3 col-md-3"><span className="menu_img"><img
																	src={spoon} alt="img" /></span></div>
																<div className="col-lg-3 col-md-3"><span className="menu_img" /></div>
															</div>
															<div className="row add_div">
																{hit.categories.map((options,index) =>
																	<div key={`cat_${index}`} className="col-md-3">
																		<div className="menu_box">
																			<h4>{options.name}</h4>
																			<ul>
																				{options.sub_category.map((sub, index) =>
																					<li key={`sub_cat_${index}`} ><a className="dropdown-item mycate"
																						href={"/search?" + sub_id1 + '' + sub.id}>
																						{sub.name}
																					</a>
																					</li>
																				)}
																			</ul>
																		</div>
																	</div>
																)}
															</div>
														</div>
													</div>
												</li>
											</ul>
										</div>
										<form action={"/search?" + string} >
											<div className="filter_select">
												<button className="dropdown-toggle filtrar_btn" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Filtrar</button>
												<div className="dropdown-menu filter_menu">
													<div className="px-0 container">
														<div className="filter_form">
															<div className="row">
															{(hit.difficulties && hit.difficulties.length > 0) ? 
																<div className="col-lg-6">
																	<div className="form-group">
																		<label>Nivel de dificultad</label>
																		<select className="form-control select_custom" name="difficultyLevel">
																			<option value="" disabled="">Select dificultad</option>
																			{hit.difficulties.map((options,index) =>
																				<option key={`difficultyLevel_option_${index}`} value={options.id}>{options.name}</option>
																			)}
																		</select>
																	</div>
																</div>
																 : 
																 ""}
																{(hit.meals && hit.meals.length > 0) ? 
																	<div className="col-lg-6">
																		<div className="form-group">
																			<label>Comida</label>
																			<select className="form-control select_custom" name="meal" >
																				<option value="" disabled="">Select Comida</option>
																				{hit.meals.map((options, index) =>
																					<option key={`meal_${index}`} value={options.id}>{options.name}</option>
																				)}
																			</select>
																		</div>
																	</div>
																 : 
																  ""}
																{(hit.occasions && hit.occasions.length > 0) ? 
																<div className="col-lg-6">
																	<div className="form-group">
																		<label>Ocations</label>
																		<select className="form-control select_custom" name="occasions" >
																			<option value="" disabled="">Select Ocations</option>
																			{hit.occasions.map((options, index) =>
																				<option key={`occasions_${index}`} value={options.id}>{options.name}</option>
																			)}
																		</select>
																	</div>
																</div>
																: 
																""}
																{(hit.diets && hit.diets.length > 0) ?
																<div className="col-lg-6">
																	<div className="form-group">
																		<label>Dieta</label>
																		<select className="form-control select_custom" name="diet" >
																			<option value="" disabled="">Select Dieta </option>
																			{hit.diets.map((options, index) =>
																				<option key={`diet_${index}`} value={options.id}>{options.name}</option>
																			)}
																		</select>
																	</div>
																</div>
																: 
																""}
																{(hit.cuisines && hit.cuisines.length > 0) ?
																<div className="col-lg-6">
																	<div className="form-group">
																		<label>Cocina</label>
																		<select className="form-control select_custom" name="cusine"  >
																			<option value="" disabled="">Select Cocina</option>
																			{hit.cuisines.map((options, index) =>
																				<option key={`cusine_${index}`} value={options.id}>{options.name}</option>
																			)}
																		</select>
																	</div>
																</div>
																: 
																""}
																{(hit.dish_style && hit.dish_style.length > 0) ?
																<div className="col-lg-6">
																	<div className="form-group">
																		<label>Type of plate</label>
																		<select className="form-control select_custom" name="dishStyle" >
																			<option value="" disabled="">Select Type of plate </option>
																			{hit.dish_style.map((options, index) =>
																				<option key={`dishStyle_${index}`} value={options.id}>{options.name}</option>
																			)}
																		</select>
																	</div>
																</div>
																: 
																""}
															</div>
															
															
															<div className="row">
																<div className="col-12">
																	<h6 className="filter_tags">Tags</h6>
																</div>
																<div className="col-12">
																	<ul className="tags_name">
																		{hit.tags.map((options, index) =>
																			<li key={`tags_name_${index}`} className="selected_users">
																				<input type="checkbox" name="tags" className="checkbox_select" value={options.tag} />
																				<span>{options.tag}</span>
																			</li>
																		)}
																	</ul>
																</div>
																<div className="col-12 text-right">
																	<button type="button"  className="cancel_btn">Cancel</button>
																	<button className="btn_cus">Filtrar</button>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</form>
										<div class="dropdown nuestros_btn">
										<button class="btn productos_btn dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
										Productos
											
										</button>
										<div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
											{/* <a class="dropdown-item" onClick={this.fetchUrl.bind(this,url)}>Beneficios de las Especias en Té</a> */}
											<a class="dropdown-item"  onClick={this.fetchUrl.bind(this,url1)}>Product Catalog</a>
											{/* <a class="dropdown-item"  onClick={this.fetchUrl.bind(this,url2)}>Recetas Típicas Panameñas</a> */}
										</div>
										
										</div>
										<div class="dropdown nuestros_btn">
										<button class="btn productos_btn dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
										Recetarios
											
										</button>
										<div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
											<a class="dropdown-item"  onClick={this.fetchUrl.bind(this,url2)}>Recetas Típicas Panameñas</a>
											<a class="dropdown-item" onClick={this.fetchUrl.bind(this,url)}>Beneficios de las Especias en Té</a>
											<a class="dropdown-item"  onClick={this.fetchUrl.bind(this,url3)}>Recetario Navideño</a>
										</div>
										
										</div>
										{/* <button className="nav-link dropdown-toggle productor_btn"  aria-expanded="false" onClick={this.fetchUrl.bind(this,url1)}>Nuestros Productos</button> */}
										{(() => {

											if (userID != null) {
												return <Link to={"/my-favourite"} className="like add_wishlist" role="button"
												><i className="fas fa-heart"></i></Link>

											}
											else {

												return <button style={{ background: '#fff' }} className="like add_wishlist" data-toggle="modal"
													data-target="#myModal" ><i className="fas fa-heart"></i></button>
											}

										})()}
									</nav>
									<UserLogin loginChange={this.loginChange} />
								</div>
							)}
						</div>
					</div>
					<NotificationContainer />
				</div>
			</header>
		)
	}
}

export default Header;
import React, { Component } from 'react';
import white_newslater from '../../images/white_newslater.png';
import logo from '../../images/logo.png';
import axios from 'axios';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import $ from 'jquery';
const API = process.env.REACT_APP_API_ENDPOINT + 'subscribe';

class Newslatter extends Component {
	constructor(props) {
		super(props);
		this.state = {
			hits: [],
			name: '',
			fields: {},
			errors: {},
			isLoading: false,

		};
		this.createNotification = this.createNotification.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.submituserRegistrationForm = this.submituserRegistrationForm.bind(this);
	}
	handleChange(e) {
		let fields = this.state.fields;
		fields[e.target.name] = e.target.value;
		this.setState({
			fields
		});
	}
	submituserRegistrationForm(e) {
		e.preventDefault();
		if (this.validateForm()) {
			let fields = {};
			fields["emailid"] = "";
			fields["firstname"] = "";
			fields["lastname"] = "";
			this.setState({ fields: fields });
			this.fetchEntities()
		}
		
	}
	validateForm() {
		let fields = this.state.fields;
		let errors = {};
		let formIsValid = true;
		if (!fields["emailid"]) {
			formIsValid = false;
			errors["emailid"] = "Please enter your email";
		}

		if (typeof fields["emailid"] !== "undefined") {
			var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
			if (!pattern.test(fields["emailid"])) {
				formIsValid = false;
				errors["emailid"] = "Por favor poner un Email válido.";
			}
		}
		this.setState({
			errors: errors
		});
		return formIsValid;


	}
	createNotification = (type, message) => {

		return () => {
			switch (type) {
				default:
					return 'default';
				case 'success':
					NotificationManager.success('Gracias por su suscripción.');
					this.setState({ isLoading: false })
					$('body').removeClass('modal-open');
					$('.modal-backdrop').remove();
					$("#email").val("	");
					break;
				case 'error':
					NotificationManager.error(message);
					this.setState({ isLoading: false })
					$('body').removeClass('modal-open');
					$('.modal-backdrop').remove();
					$("#email").val("	");
					break;
			}
		};
	};
	fetchEntities() {
		let reqParam = {
			first_name : this.state.fields.firstname,
			last_name : this.state.fields.lastname,
			email: this.state.fields.emailid
		}
		this.setState({ isLoading: true })
		axios.post(API, reqParam)
			.then(response => {
				this.createNotification('success', response.data.message)();
			})
			.catch(error => {
				this.createNotification('error', error.response.data.message)();
			});
	}
	render() {
		const { isLoading } = this.state
		if (isLoading) {
			return <div className="loading"></div>;
		}
		return (
			<div>
				<div className="container">
					<div className="row">
						<div className="col-lg-12 col-12">
							<div className="title_head">
								<h3 className="wow flipInY animated">Subscríbete a nuestro Newsletter</h3>
								{/* <img src={white_newslater} alt="" /> */}
								<p className="wow fadeInUp">Suscríbete y recibirás recetas, tips de cocina, acceso a contenido exclusivo y otras sorpresas.</p>
								<button className="btn_cus yellow wow flipInY" data-toggle="modal" data-target="#newsletter"> Suscribirse</button>
							</div>
						</div>
					</div>
				</div>
				<div className="modal fade theme_popup" id="newsletter" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
					<div className="modal-dialog modal-dialog-centered  modal-lg" role="document">
						<div className="modal-content">
							<div className="modal-header">
								<button type="button" className="close" data-dismiss="modal" aria-label="Close" aria-hidden="true">
									&times;
                         </button>
							</div>
							<div className="modal-body">
								<div className="news_letter_box">
									<div className="logo_container">
										<img src={logo} className="" alt="" />
									</div>
									<div className="news_content">
										{/* <span>Get delicious</span> */}
										{/* <h4></h4> */}
										
										<p>¿Quieres recibir deliciosas recetas de La Doña a tu correo? Suscríbete!</p>
										<div className="subscribe_div">
											<form method="post" name="userRegistrationForm" onSubmit={this.submituserRegistrationForm} >
											<div className="form-group">
											{/* <label>  Nombre</label> */}
												<input type="text"
													className="form-control"
													name="firstname"
													value={this.state.fields.firstname}
													onChange={this.handleChange}
													placeholder="Nombre"
													
												/>
											</div>
											<div className="form-group">
											{/* <label>Apellido</label> */}
												<input type="text"
													className="form-control"
													name="lastname"
													value={this.state.fields.lastname}
													onChange={this.handleChange}
													placeholder="Apellido"
													
												/>
											</div>
												<div className="form-group">
												{/* <label>Email</label> */}
													<input type="text"
														className="form-control"
														name="emailid"
														value={this.state.fields.emailid}
														onChange={this.handleChange}
														placeholder="Email"
														
													/>
													<div className="errorMsg">{this.state.errors.emailid}</div>
												</div>
												
												<div className="button_Section">
													<button className="btn_newslatter" data-dismiss="modal" style={{ cursor: 'pointer' }}>No Gracias</button>
													<button
														className="btn theme_btn"
														id="theme_btn"
													>
														Suscribirse
													</button>
												</div>
											</form>
										</div>
									</div>
									<NotificationContainer />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default Newslatter
import React, { Component } from 'react'
import Userlogin from './Login'
import { Redirect } from 'react-router';
import axios from 'axios';
import $ from 'jquery'
import { NotificationContainer, NotificationManager } from 'react-notifications';

// const API = 'https://trootechproducts.com:8014/rest/V1/customers';

const API = process.env.REACT_APP_SIGN_UP_ENDPOINT +'rest/V1/customers';

const token = process.env.REACT_APP_ADMIN_TOKEN
class Signup extends Component {
	constructor(props) {
		super(props);
		this.state = {
			fields: {},
			errors: {},
			email1: '',
			isLoading: false,
			redirectToReferrer: false,

		}
		this.createNotification = this.createNotification.bind(this);
		this.fetchEntities = this.fetchEntities.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.submituserRegistrationForm = this.submituserRegistrationForm.bind(this);

	};
	createNotification = (type, message) => {
		return () => {
			switch (type) {
				default:
					return 'default';
				case 'success':
					NotificationManager.success(message, 'Register Successfully', 2000);
					this.setState({ isLoading: false });
					break;
				case 'error':
					this.setState({ isLoading: false });
					NotificationManager.error(message, 'Error!', 5000);
					break;
			}
		};
	};
	fetchEntities() {
console.log(this.state.fields.birthday);
		const data =
		{
			customer:
			{
				email: this.state.fields.emailid,
				dob: this.state.fields.birthday,
				gender: this.state.fields.gender,
				firstname: this.state.fields.username,
				lastname: this.state.fields.lastname,
				storeId: 1,
				websiteId: 1
			},
			password: this.state.fields.password
		}

		const headers = {
			'Content-Type': 'application/json',
			'Authorization': token,

			'Access-Control-Allow-Origin': '*'
		}
		this.setState({ isLoading: true });
		axios.post(API, data, { headers: headers })
			.then(response => {
				localStorage.setItem('id', response.data.id);
				localStorage.setItem('firstname', response.data.firstname);
				localStorage.setItem('isLogin', true);
				this.props.stateChange('isLogin', true);
				this.createNotification('success', response.message, 1200)();
				this.setState({
					redirectToReferrer: true,
				});
			})
			.catch(error => {
				if (error.response) {
					/*
					* The request was made and the server responded with a
					* status code that falls out of the range of 2xx
					*/
					this.createNotification('error', error.response.data.message)();
					console.log(error.response.data);
					console.log(error.response.status);
					console.log(error.response.headers);
					//.createNotification('error', error.response.data.message)();	
				} else if (error.request) {
					/*
					* The request was made but no response was received, `error.request`
					* is an instance of XMLHttpRequest in the browser and an instance
					* of http.ClientRequest in Node.js
					*/
					console.log(error.request);
				} else {
					console.log('Error', error.message);
				}
				console.log(error.config);
			});


	}
	handleChange(e) {
		let fields = this.state.fields;
		fields[e.target.name] = e.target.value;
		this.setState({
			fields
		});

	}

	submituserRegistrationForm(e) {
		e.preventDefault();
		if (this.validateForm()) {
			let fields = {};
			fields["username"] = "";
			fields["lastname"] = "";
			fields["emailid"] = "";
			fields["birthday"] = "";
			fields["gender"] = "";
			fields["password"] = "";
			fields["confirmpassword"] = "";
			this.setState({ fields: fields });
			this.fetchEntities()
		}

	}
	validateForm() {

		let fields = this.state.fields;
		let errors = {};
		let formIsValid = true;

		if (!fields["username"]) {
			formIsValid = false;
			errors["username"] = "Please enter your name.";
		}

		if (typeof fields["username"] !== "undefined") {
			if (!fields["username"].match(/^[a-zA-Z ]*$/)) {
				formIsValid = false;
				errors["username"] = "Please Ingresar  alphabet characters only.";
			}
		}
		if (!fields["lastname"]) {
			formIsValid = false;
			errors["lastname"] = "Please Ingresar  your Apellido.";
		}
		if (!fields["birthday"]) {
			formIsValid = false;
			errors["birthday"] = "Please Ingresar  your date of birth.";
		}

		if (typeof fields["username"] !== "undefined") {
			if (!fields["username"].match(/^[a-zA-Z ]*$/)) {
				formIsValid = false;
				errors["username"] = "Please Ingresar  alphabet characters only.";
			}
		}

		if (!fields["emailid"]) {
			formIsValid = false;
			errors["emailid"] = "Please Ingresar  your email-ID.";
		}

		if (typeof fields["emailid"] !== "undefined") {
			var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
			if (!pattern.test(fields["emailid"])) {
				formIsValid = false;
				errors["emailid"] = "Please Ingresar  valid email.";
			}
		}
		if (!fields["password"]) {
			formIsValid = false;
			errors["password"] = "Please Ingresar  your password.";
		}

		if (typeof fields["password"] !== "undefined") {
			if (!fields["password"]) {
				formIsValid = false;
				errors["password"] = "Please Ingresar  your Password.";
			} else {
				if (!fields["password"].match(/^.*(?=.{6,}).*$/)) {
					formIsValid = false;
					errors["password"] = "Minimum password length 6 is required";
				}
				
				else if (!fields["password"].match(/^.*(?=.*\d).*$/)) {
					formIsValid = false;
					errors["password"] = "Atleast one digit is required";
				}
				else if (!fields["password"].match(/^.*(?=.*[A-Za-z]).*$/)) {
					formIsValid = false;
					errors["password"] = "Atleast one alpha character is required";
				}
			}
		}

		if (!fields["confirmpassword"]) {
			formIsValid = false;
			errors["confirmpassword"] = "Please Ingresar  your Confirmar Contraseña.";
		}

		if (typeof fields["confirmpassword"] !== "undefined") {
			if (fields["password"] !== (fields["confirmpassword"])) {
				formIsValid = false;
				errors["confirmpassword"] = "Password and Confirmar Contraseña is not match.";
			}
		}

		this.setState({
			errors: errors
		});
		return formIsValid;


	}
	componentWillMount() {
		$('#myModal').remove();
		$('.modal-backdrop').remove()
		$(document.body).removeClass("modal-open");
	}


	render() {
		console.log(this.state.fields && this.state.fields)
		const { isLoading } = this.state
		if (isLoading) {
			return <div className="loading"></div>;
		}

		if (this.state.redirectToReferrer) {
			return (<Redirect to={'/'} />)
		}

		return (
			<section className="login_section">
				<div className="container">
					<div className="row">
						<div className="col-12 col-lg-6 col-md-8 m-auto">
							<div className="white_box wow fadeIn animated">
								<div className="box_header">

									<h4>Creat tu cuenta</h4>
								</div>
								<div className="form_login">
									<form method="post" name="userRegistrationForm" onSubmit={this.submituserRegistrationForm} >
										<div className="form-group">
											<label>Nombre<span className="red"> *</span></label>
											<input type="text" className="form-control" name="username" value={this.state.fields.username} onChange={this.handleChange} placeholder="Ingresar Nombre" />
											<div className="errorMsg">{this.state.errors.username}</div>
										</div>
										<div className="form-group">
											<label>Apellido<span className="red"> *</span></label>
											<input type="text" className="form-control" name="lastname" value={this.state.fields.lastname} onChange={this.handleChange} placeholder="Ingresar Apellido" />
											<div className="errorMsg">{this.state.errors.lastname}</div>
										</div>
										<div className="form-group">
											<label>Email<span className="red"> *</span></label>
											<input type="text" className="form-control" name="emailid" value={this.state.fields.emailid} onChange={this.handleChange} placeholder="Add Email" />
											<div className="errorMsg">{this.state.errors.emailid}</div>
										</div>
										<div className="form-group">
											<label for="gender">Gender</label>
												<select name="gender" id="gender" className="form-control" value={this.state.fields.gender} onChange={this.handleChange}>
													<option value={2}>Femenino</option>
													<option value={1}>Masculino</option>
													<option value={75}>Otro</option>
													<option value={3}>Prefiero no decir</option>
												</select>
										</div>
										<div className="form-group">
											<label for="birthday">Date of birth<span className="red"> *</span></label>
  											<input type="date" className="form-control" id="birthday" name="birthday" value={this.state.fields.birthday} onChange={this.handleChange}/>
											<div className="errorMsg">{this.state.errors.birthday}</div>
										</div>
										<div className="form-group">
											<label>Contraseña<span className="red"> *</span></label>
											<input type="password" className="form-control" name="password" autocomplete="current-password" value={this.state.fields.password} onChange={this.handleChange} placeholder="Ingresar Contraseña" />
											<div className="errorMsg">{this.state.errors.password}</div>
										</div>
										<div className="form-group">
											<label>Confirmar Contraseña<span className="red"> *</span></label>
											<input type="password" className="form-control" name="confirmpassword" value={this.state.fields.confirmpassword} onChange={this.handleChange} placeholder="Confirmar Contraseña" />
											<div className="errorMsg">{this.state.errors.confirmpassword}</div>
										</div>
										<div className="bottom_login">
											<button className="btn btn_red width120">Registrate</button>
											{/* <input type="submit" className="button"  value="Register"/> */}

											<p>¿Ya tiens cuenta? Ingresa <button data-toggle="modal"
												data-target="#myModal" className="main_colr pl-1">Login</button></p>
										</div>

									</form>
								</div>
								<NotificationContainer />

							</div>
						</div>

					</div>
					<Userlogin />
				</div>
			</section>
		)
	}
}

export default Signup
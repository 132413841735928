import React, { Component } from 'react'
import MostPopular from './index/Most_popular';
import TopRecipe from './index/Top_recipe';
// import UserLogin from './index/Login';

import LatestRecipe from './index/Latest_recipe';
import Combos from './index/Combos';
import Newslatter from './index/Newslatter';
//Import Images
import try_combo from '../images/try_combo.png';
import most_back from '../images/most_back.png';
import news_later from '../images/news_later.png';
import { Common } from '../util/Common';
import  $ from 'jquery';
import { bind } from 'lodash';


var divStyle2 = {
	backgroundImage: 'url(' + try_combo + ')',
	display : 'none'
}
var divStyle3 = {
	backgroundImage: 'url(' + most_back + ')',
	display : 'none'
}
var divStyle4 = {
	backgroundImage: 'url(' + news_later + ')'
}
class Index extends Component {
	constructor() {
		super();
		this.state = {
			isLoading: false,
		}
		
		// this.handleScroll = this.handleScroll.bind(this);
	}
	componentDidMount() {
		window.addEventListener('scroll', this.handleScroll, true);
		setTimeout(()=>{
			new Common().loadScript([
				'/assets/js/custom.js',
				'/assets/js/bootstrap.min.js'

			]);
		});
	}
	
	componentWillUnmount() {
		window.removeEventListener('scroll', this.handleScroll);
	}
	handleScroll = e => {
		var lastScrollY = window.scrollY;
		
		if (lastScrollY > 500 ) {
			$("#latest_receipe").css('display','block')
			}
		if (lastScrollY > 1500 ) {
			$("#combo_receipe").css('display','block')
		}
		if (lastScrollY > 2000 ) {
			$("#most_popular_recipe").css('display','block')
		  
		}
		
	  };
	
	render() {
		const { isLoading } = this.state
		if (isLoading) {
			return <div className="loading"></div>;
		}
		return (
			<div>
				<section className="banner_home" >
				 <TopRecipe /> 
				</section>

				<section className="latest_recipes_sec" id="latest_receipe" style={{display:'none'}}>
					<LatestRecipe />
				</section>

				<section className="try_this_combo_sec"style={divStyle2} id="combo_receipe">
					<Combos />
				</section>

				<section className="most_popular_recipes" style={divStyle3} id="most_popular_recipe">
					 <MostPopular /> 
				</section>

				<section className="subscribe_newslater" style={divStyle4}>
					<Newslatter />
				</section>
			</div>

		)
	}
}

export default Index;


import React, { Component } from 'react';
import axios from 'axios';
import time1 from '../../images/time1.png';
import time2 from '../../images/time2.png';
import time3 from '../../images/time3.png';
import time4 from '../../images/time4.png';
import Login from '../index/Login'
import { Link } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';
import $ from 'jquery'

let isFirst = 2;
const API = process.env.REACT_APP_API_ENDPOINT + 'sliders';
const FAVOURITE_API = process.env.REACT_APP_API_ENDPOINT + 'fevorite';
const VIDEOAPI = process.env.REACT_APP_API_ENDPOINT + 'tutorial_video';

class Top_recipe extends Component {
	constructor(props) {
		super(props);
		this.state = {
			hits: [],
			videoUrl : "",
			user: '',

		};
		this.favourite = this.favourite.bind(this);

	}
	createNotification = (type, message) => {
		return () => {
			switch (type) {
				default:
					return 'default';
				case 'success':
					NotificationManager.success(message, 'Title here', 1000);
					this.fetchEntities();
					break;
				case 'error':
					NotificationManager.error(message, 'Error!', 5000);
					break;
			}
		};
	};
	favourite(is_favourite, recipeID) {
		var isFav
		(() => {
			if (is_favourite === 1) {
				return isFav = 0
			}
			else {

				return isFav = 1
			}
		})()
		let reqParam = {
			user_id: localStorage.getItem('id'),
			recipe_id: recipeID,
			is_favourite: isFav
		}
		axios.post(FAVOURITE_API, reqParam)
			.then(response => {
				this.createNotification('success', response.data.message)();
				// console.log("refresh")
				// setTimeout(function () {
				// 	window.location.reload();
				// }, 1100);
			})
	}
	tutorialVideo(){
		axios.post(VIDEOAPI)
			.then(result => this.setState({
				videoUrl: result.data.data
			}));
			
	}
	fetchEntities() {
		let reqParam = {
			user_id: localStorage.getItem('id')
		}
		axios.post(API, reqParam)
			.then((result) => {
				this.setState({hits: result.data.sliders})
			});
	}
	componentDidMount() {
		this.tutorialVideo();
		this.fetchEntities();
	}
	render() {
		var userID = localStorage.getItem('isLogin')
		if (userID && isFirst === 2) {
			this.fetchEntities();
			isFirst = 1;
		}
		// this.state.user =userID;
		const { hits } = this.state;
		const url = this.state.videoUrl
		localStorage.setItem("videoStatus",url.status)
		
		return (
			<div className="owl-demo">
				<div className="owl-carousel owl-theme" id="banner_slide">

					{hits.map((hit, index) =>
						<div className="" key={`top_recipe_${index}`}>
							{(() => {
								//  console.log(localStorage.getItem('isLogin'))
								if (userID != null && hit && hit.recipe_id !==null) {
									return <button
										className="like slider_like"
										onClick={this.favourite.bind(this, hit.is_favourite, hit.recipe_id)}
									><i className={hit.is_favourite === 1 ? 'fas fa-heart' : 'fas fa-heart unliked'}>
										</i></button>
								}
								else {
									if(hit && hit.recipe_id !==null) {
										return <button
										className="like slider_like"
										data-toggle="modal"
										data-target="#myModal"
									>
										<i className="fas fa-heart unliked"></i></button>
									}
								}

							})()}
							<Link to={(hit.title_slug !== null) ?"/recipe/" + hit.title_slug : ''} className="slider_link">
								<div className="item" style={{ backgroundImage: `url(${hit.image})` }} key={hit.id}>
									<div className="container">
										<div className="banner_inner">
										{hit && hit.recipe_id !==null &&
											<div className="banner_text">
												<div className="btn_round">
													<div className="round_inner">
														<div className="bnr_btn">
															<button className="btn_cus wow fadeIn animated">{hit.dish_style}</button>
														</div>
														<h1 className="wow bounce animated">{hit.title}</h1>

														{/* <a href="#" className="like" role="button" data-toggle="modal" data-target="#myModal" ><i className="fas fa-heart unliked"></i></a> */}
													</div>
												</div>
											</div>
									}
											{hit && hit.recipe_id !==null &&
											<div className="time_box_main">
												<div className="time_box wow flipInY animated">
													<span className="img_left"><img src={time1} alt="" /></span>
													<div className="text_field">
														<h4>Tiempo de Preparación</h4>
														<span>{hit.prep_time}</span>
													</div>
												</div>
												<div className="time_box wow flipInY animated">
													<span className="img_left"><img src={time2} alt="" /></span>
													<div className="text_field">
														<h4>Horas de Cocción</h4>
														<span>{hit.cook_time}</span>
													</div>
												</div>
												<div className="time_box wow flipInY animated">
													<span className="img_left"><img src={time3} alt="" /></span>
													<div className="text_field">
														<h4>Horas Totales</h4>
														<span>{hit.total_time}</span>
													</div>
												</div>
												<div className="time_box wow flipInY animated">
													<span className="img_left"><img src={time4} alt="" /></span>
													<div className="text_field">
														<h4>Ingredientes</h4>
														<span>{hit.total_ingredients}</span>
													</div>
												</div>
											</div>
											}
										</div>
									</div>
								</div>
							</Link>
						</div>
					)}
				</div>
				<Login />
				<div className="slide-progress"></div>
				<div className="modal fade video" id="vidoe_modal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true" >
					<div className="modal-dialog modal-dialog-centered modal-lg" role="document">
						<div className="modal-content">
							<div className="modal-body video_modal">
								<button className="action-close close cloase_btn" data-role="closeBtn" type="button" data-dismiss="modal">
									<span>×</span>
								</button>
								<div className="test_fb_wrapper">
									<div className="test_fb_wrapper_responsive">
										
										<video id="firstPageVideo" width="100%" height="100%" controls src={url.video} type="video/mp4" />
										
										</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default Top_recipe
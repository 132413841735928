import React, { Component } from 'react'
import { BrowserRouter as Router, Route} from "react-router-dom";
import { createBrowserHistory } from 'history'
import Header from './Header';
import Index from './Index';
import Footer from './Footer';
import Recipe_details from './Recipe_details';
import Combo_details from './Combo_details';
import My_favourite from './My_favourite';
import About_us from './AboutUs' 
import Contact_us from './Contact_us' 
import Privacy_policy from './Privacy' 
import Service_policy from './Service' 
import Search from './Search';
import Terms from './Terms';
import Signup from './index/Signup';
import Download from './download';
import Beneficios from './Beneficios';
import Catalog from './Catalog';
import Recetas from './Recetas';
import { Common } from '../util/Common';
import { withRouter } from "react-router";
import ReactGA from 'react-ga';
const trackingId = "UA-76962460-1"; // Replace with your Google Analytics tracking ID

const ChangeTracker = withRouter(({match, location, history}) => {
	ReactGA.initialize(trackingId);
	ReactGA.set({ page: location.pathname }); // Update the user's current page
	ReactGA.pageview(location.pathname); // Record a pageview for the given page
    // console.log( location.pathname);
    return false;
})

export default class Main extends Component {

	constructor(props) {
		super(props);
		this.state = {
			isLogin:false
		};
		this.stateChange = this.stateChange.bind(this);
	}
	
	
	componentWillMount(){
		const common = new Common();
		common.loadScript([
			'https://code.jquery.com/jquery-3.5.1.min.js',
			'/assets/js/slimscrollmin.js',
			// 'https://cdnjs.cloudflare.com/ajax/libs/jQuery-slimScroll/1.3.8/jquery.slimscroll.min.js',
			'https://stackpath.bootstrapcdn.com/bootstrap/4.5.0/js/bootstrap.min.js',
			'/assets/js/owl.carousel.min.js',
			'https://cdnjs.cloudflare.com/ajax/libs/wow/1.1.2/wow.js',
			// 'https://cdnjs.cloudflare.com/ajax/libs/wow/0.1.12/wow.min.js',
		]);
	}
	
	stateChange(stateLabel, value){
		localStorage.setItem('isLogin',true);
		this.setState({ [stateLabel]: value });
	}
	
	render() {
		
		var string=''
		let searchUrl = window.location.href;
		var spilt_url1 = searchUrl.split('/');  //Split Url
		var splt_strr = spilt_url1[3];
		
        return (
			<div id="wrapper" >
				
				{(() => {
					if(splt_strr == "download"){
						return <Router history={createBrowserHistory}>
										<Route path={"/download"} component={Download} />	
								  </Router>
						}
						else if(splt_strr == "beneficios-de-las-especias-en-te"){
							return <Router history={createBrowserHistory}>
										<Route path="/beneficios-de-las-especias-en-te" component={Beneficios} />
								  </Router>
						}
						else if(splt_strr == "catalogo-de-productos"){
							return <Router history={createBrowserHistory}>
										<Route path="/catalogo-de-productos" component={Catalog} />
								  </Router>
						}
						else if(splt_strr == "recetas-tipicas-panamenas"){
							return <Router history={createBrowserHistory}>
										<Route path="/recetas-tipicas-panamenas" component={Recetas} />
								  </Router>
						}
						else{
							return  <Router history={createBrowserHistory}>
										<Header stateChange={this.stateChange}/>
										<Route exact path={["/", "/index"]} component={Index} history={createBrowserHistory}></Route>
										<Route path="/recipe/:title_slug" component={Recipe_details}  history={createBrowserHistory} />
										<Route path="/about-us/" component={About_us} />
										<Route path="/sign-up/" render={props => <Signup {...props} stateChange={this.stateChange} />} />
										<Route path="/contact-us/" component={Contact_us} />
										<Route path="/privacy-policy/" component={Privacy_policy} />
										<Route path="/product-policy/" component={Service_policy} />
										<Route path='/combo-details/:title_slug' component={Combo_details} />
										<Route path='/my-favourite/' component={My_favourite} />
										<Route path={"/search"+string} component={Search} />
										<Route path={"/terms-conditions"} component={Terms} />	
										<ChangeTracker />
										<Footer />
									</Router>
						}
				})()}
               
            </div>
        )
    }
}
